<template>
  <div class="reports">
    <h1>Client Performance</h1>
    <hr>
    <h2>{{ $auth.user }}</h2>
    <section>
      <b-field label="Clients">
          <b-select v-model='currentClient' placeholder="Select a client" >
            <option v-for="client in clients" :key="client.message">{{ client.message }}</option>
          </b-select>
      </b-field>

      <b-field label="Start Time">
        <b-datetimepicker
            v-model="datetimeStart"
            placeholder="Select start date"
            icon="calendar-day"
            locale="en-CA">
          <template #left>
            <b-button
                label="This morning"
                type="is-primary"
                icon-left="clock"
                @click="date = new Date(); datetimeStart = new Date(date.setHours(0,0))" />
          </template>
        </b-datetimepicker>
          <b-button label="Clear"
                    type="is-secondary"
                    @click="datetimeStart = null" />
      </b-field>

      <b-field label="End Time">
        <b-datetimepicker
            v-model="datetimeEnd"
            placeholder="Select end date"
            icon="calendar-day"
            locale="en-CA">
          <template #left>
            <b-button
                label="Now"
                type="is-primary"
                icon-left="clock"
                @click="datetimeEnd = new Date()" />
          </template>
        </b-datetimepicker>
          <b-button label="Clear"
                    type="is-secondary"
                    @click="datetimeEnd = null" />
      </b-field>
      <br>
      <b-field>
        <b-button @click="onCardSubmit" type="is-primary" :disabled="currentClient == null">Submit</b-button>
      </b-field>
    </section>
    <div v-if="cards.length">
      <ClientCard v-for="card in cards"
                  :key="card.id"
                  v-bind="card"
                  @remove='removeCard(0)'/>
    </div>
    <br>
  </div>
</template>

<script>
import ClientCard from "@/components/ClientCard.vue";

import axios from "axios";

import { apiKey } from "@/../api.json";

export default {
  components: {
    ClientCard,
  },
  data() {
    return {
      datetimeEnd: null,
      datetimeStart: null,
      cards: [],
      currentClient: null,
      clients: [
        { message: 'CS2' },
        { message: 'CSC' },
        { message: 'D2C' },
        { message: 'GOLDIE' },
        { message: 'GOLDIE2' },
        { message: 'HOBECO' },
        { message: 'HOBI' },
        { message: 'ITANCIA' },
        { message: 'LINIO' },
        { message: 'LONESTARMOBILITY' },
        { message: 'LOOP' },
        { message: 'MAGIC' },
        { message: 'NORTHSTARMOBILITY' },
        { message: 'RETURNS' },
        { message: 'WALKERANDWALKER' },
      ],
    }
  },
  computed: {
    isReady: function() {
      return this.datetimeStart != null;
    },
  },
  methods: {
    addCard: function(entry) {
      // console.log("Card part");
      // console.log(entry)
      if(entry.orders){
        let temp1 = { orders: entry.orders };
        this.cards.push(temp1);
      } 
      if(entry.cancellations){
        let temp2 = { cancellations: entry.cancellations };
        this.cards.push(temp2);
      } 
      if(entry.returns){
        let temp3 = { returns: entry.returns };
        this.cards.push(temp3);
      } 
    },
    removeCard: function() {
      this.cards.pop();
      this.cards.pop();
      this.cards.pop();
    },
    onCardSubmit: function() {
      const loadingComponent = this.$buefy.loading.open({
                    container: this.$refs.loadable});
      this.removeCard();
      // console.log('Order number submitted: ', this.lpn)
      // const axios = require('axios').default;

      // axios.post('http://localhost:3001/api/api_in.php',
      axios.post('https://finchwright.com/api/api_in.php',
                 { type: 'clientsearch',
                   client: this.currentClient,
                   datetimeStart: this.datetimeStart,
                   datetimeEnd: this.datetimeEnd,
                   apiKey: apiKey },
                 // { headers: { Authorization: `Bearer ${this.apiKey}` } }
                )
           .then((response) => {
             // console.log("Axios part");
             // console.log(response.data);
             // this.orderentry = response.data;
             loadingComponent.close();
             this.addCard(response.data);
           })
           .catch((error) => { console.log(error); });
           // .finally();
    },
    // mounted() {
    //   this.onCardSubmit();
    // }
  }
}
</script>
