<template>
  <div class="outstanding-returns">
    <h1>Outstanding Returns</h1>
    <hr>
      <b-button refs="loadable" @click="onSubmit" type="is-primary" label="Search" />
    <br>

    <div v-if="cards.length">
      <ReportCard v-for="card in cards"
                 :key="card.id"
                 v-bind="card"
                 @remove='removeCard'/>
    </div>
  </div>
</template>

<script>
import ReportCard from "@/components/ReportCard.vue"
import axios from "axios"

import { apiKey } from "@/../api/api.json";

export default {
  components: {
    ReportCard,
  },
  data() {
    return {
      cards: [],
    }
  },
  methods: {
    addCard: function(entry) {
      // console.log("Card part");
      // console.log(entry)
      if(entry.lpn_data && entry.lpn_data.length){
        var test_a = { lpn_data: entry.lpn_data };
        this.cards.push(test_a);
      }
      if(entry.order_data && entry.order_data.length){
        var test_b = { order_data: entry.order_data };
        this.cards.push(test_b);
      }
      if(entry.date_data && entry.date_data.length){
        var test_c = { order_data: entry.date_data };
        this.cards.push(test_c);
      }
      // else {
      //   this.lpnNotFound = true;
      // }
    },
    removeCard: function() {
      this.cards.pop();
      this.cards.pop();
      this.cards.pop();
      this.lpn = '';
    },
    onSubmit() {
      this.orderNotFound = false;
      const loadingComponent = this.$buefy.loading.open({
                    container: this.$refs.loadable
                });
      this.cards.pop();
      // console.log('Order number submitted: ', this.ordernum)
      // const axios = require('axios').default;

      // axios.post('http://localhost:3001/api/api_in.php',
      axios.post('https://finchwright.com/api/api_in.php',
                 { type: 'outstandingreturns', apiKey: apiKey },
                 // { headers: { Authorization: `Bearer ${this.apiKey}` } }
                )
           .then((response) => {
             // console.log("Axios part");
             // console.log(response.data);
             // this.orderentry = response.data;
             this.addCard(response.data);
             loadingComponent.close();
           })
           .catch((error) => { console.log(error); });
           // .finally();
    },
  }
}
</script>
