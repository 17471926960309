<template>
  <div class="onpl-tests">
    <h1>ONPL Tests</h1>
    <hr>
    <section>
      <b-field label="Active email address" :label-position="labelPosition">
        <section>
          <b-input 
              id="active_email"
              placeholder="email"
              v-model="email">
          </b-input>
        </section>
      </b-field>
      <b-field label="Active order_hnid" :label-position="labelPosition">
        <section>
          <b-input 
              id="active_order_hnid"
              placeholder="order_hnid"
              v-model="order_hnid">
          </b-input>
        </section>
      </b-field>
      <b-field label="Active lpn" :label-position="labelPosition">
        <section>
          <b-input
              id="active_lpn"
              placeholder="lpn"
              v-model="lpn">
          </b-input>
        </section>
      </b-field>
      <b-field>
        <section>
          <b-button @click="genOrderAndAdvance">Generate and advance trade-in order (overrides active order_hnid)</b-button>
        </section>
      </b-field>
      <b-field>
        <section>
          <!-- <b-input
              @keyup.native.enter="receiveTestItemByOrderHnid"
              placeholder="order_hnid"
              v-model="order_hnid_to_receive">
          </b-input> -->
          <b-button @click="receiveTestItemByOrderHnid">Receive test item by order-hnid (overrides active lpn)</b-button>
        </section>
      </b-field>
      <!-- <b-field>
        <section>
          <b-input
              @keyup.native.enter="createTestInspectionEntry"
              placeholder="LPN"
              v-model="lpn">
          </b-input>
          <b-button @click="createTestInspectionEntry">Create test inspection entry</b-button>
        </section>
      </b-field> -->
      <b-field>
        <section>
          <!-- <b-input
              @keyup.native.enter="testInspectionPassed"
              placeholder="LPN"
              v-model="lpn_passed">
          </b-input> -->
          <b-button @click="testInspectionPassed">Inspection passed / Item received matches expected</b-button>
        </section>
      </b-field>
      <b-field>
        <section>
          <!-- <b-input
              @keyup.native.enter="generateAndSendTestCounteroffer"
              placeholder="LPN"
              v-model="lpn_counteroffer">
          </b-input> -->
          <b-button @click="generateAndSendTestCounteroffer">Generate and send counter-offer</b-button>
        </section>
      </b-field>
      <b-field>
        <section>
          <!-- <b-input
              placeholder="order_hnid"
              v-model="order_hnid">
          </b-input> -->
          <b-button @click="acceptTestAdjustment">Accept adjustment</b-button>
          <b-button @click="declineTestAdjustment">Decline adjustment</b-button>
        </section>
      </b-field>
      <b-field>
        <section>
          <!-- <b-input
              @keyup.native.enter="testOfferAccepted"
              placeholder="LPN"
              v-model="lpn_completed">
          </b-input> -->
          <b-button @click="testOfferAccepted">Counter-offer was accepted, so complete order</b-button>
        </section>
      </b-field>
      <b-field>
        <section>
          <!-- <b-input
              @keyup.native.enter="testOfferRejected"
              placeholder="LPN"
              v-model="lpn_returned">
          </b-input> -->
          <b-button @click="testOfferRejected">Counter-offer was rejected, so return order</b-button>
        </section>
      </b-field>
    </section>
    <br>

    <div v-if="checkcard.length">
      <LPNCheckCard v-for="card in checkcard"
                    :key="card.id"
                    v-bind="card"
                    @remove='removeCard'/>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
  </div>
</template>

<script>
import LPNCheckCard from "@/components/LPNCheckCard.vue"
import axios from "axios"

import { apiKey } from "@/../api/api.json";

export default {
  components: {
    LPNCheckCard,
  },
  data() {
    return {
      email: this.$auth.user.email,
      lpn: '',
      // order_hnid_to_receive: '',
      order_hnid: '',
      // offer_hnid: '',
      checkcard: [],
      lpnNotFound: false,
      isLoading: false,
      labelPosition: 'on-border',
    }
  },
  methods: {
    addCard: function(entry) {
      console.log("Card part");
      console.log(entry)
      if(entry){
        var info = { id: 1, checkinfo: entry }
        this.checkcard.pop();
        this.checkcard.push(info);
      } else {
        this.lpnNotFound = true;
      }
    },
    removeCard: function() {
      this.checkcard.pop();
      this.checkcard.pop();
      this.lpn = '';
    },
    genOrderAndAdvance() {
      this.checkcard.pop();
      this.checkcard.pop();

      // axios.post('http://localhost:3001/api/3po_in.php',
      axios.post('https://finchwright.com/api/3po_in.php',
                  { sub_type: 'onpl-test-gen-order-and-advance', email: this.email, apiKey: apiKey },
                )
            .then((response) => {
              this.addCard(response.data);
              this.isLoading = false;
              this.order_hnid = JSON.parse(response.data.order.result).order.order_hnid;
            })
            .catch((error) => { console.log(error); });
            // .finally();
    },
    receiveTestItemByOrderHnid() {
      this.checkcard.pop();
      this.checkcard.pop();

      // axios.post('http://localhost:3001/api/3po_in.php',
      axios.post('https://finchwright.com/api/3po_in.php',
                  { sub_type: 'onpl-test-receive-item-by-order-hnid', order_hnid: this.order_hnid, apiKey: apiKey },
                )
            .then((response) => {
              this.addCard(response.data);
              this.isLoading = false;
              this.lpn = response.data.lpn;
            })
            .catch((error) => { console.log(error); });
            // .finally();
    },
    // createTestInspectionEntry() {
    //   this.checkcard.pop();
    //   this.checkcard.pop();

    //   // axios.post('http://localhost:3001/api/3po_in.php',
    //   axios.post('https://finchwright.com/api/3po_in.php',
    //               { sub_type: 'onpl-test-create-inspection-entry', lpn: this.lpn, apiKey: apiKey },
    //             )
    //         .then((response) => {
    //           this.addCard(response.data);
    //           this.isLoading = false;
    //         })
    //         .catch((error) => { console.log(error); });
    //         // .finally();
    // },
    testInspectionPassed() {
      this.checkcard.pop();
      this.checkcard.pop();

      // axios.post('http://localhost:3001/api/3po_in.php',
      axios.post('https://finchwright.com/api/3po_in.php',
                  { sub_type: 'onpl-test-inspection-passed', lpn: this.lpn, apiKey: apiKey },
                )
            .then((response) => {
              this.addCard(response.data);
              this.isLoading = false;
            })
            .catch((error) => { console.log(error); });
            // .finally();
    },
    generateAndSendTestCounteroffer() {
      this.checkcard.pop();
      this.checkcard.pop();

      // axios.post('http://localhost:3001/api/3po_in.php',
      axios.post('https://finchwright.com/api/3po_in.php',
                  { sub_type: 'onpl-test-generate-and-send-counteroffer', lpn: this.lpn, apiKey: apiKey },
                )
            .then((response) => {
              this.addCard(response.data);
              this.isLoading = false;
            })
            .catch((error) => { console.log(error); });
            // .finally();
    },
    acceptTestAdjustment() {
      this.checkcard.pop();
      this.checkcard.pop();

      // axios.post('http://localhost:3001/api/3po_in.php',
      axios.post('https://finchwright.com/api/3po_in.php',
                  { sub_type: 'onpl-test-adjustment-accept', order_hnid: this.order_hnid, apiKey: apiKey },
                )
            .then((response) => {
              this.addCard(response.data);
              this.isLoading = false;
            })
            .catch((error) => { console.log(error); });
            // .finally();
    },
    declineTestAdjustment() {
      this.checkcard.pop();
      this.checkcard.pop();

      // axios.post('http://localhost:3001/api/3po_in.php',
      axios.post('https://finchwright.com/api/3po_in.php',
                  { sub_type: 'onpl-test-adjustment-decline', order_hnid: this.order_hnid, apiKey: apiKey },
                )
            .then((response) => {
              this.addCard(response.data);
              this.isLoading = false;
            })
            .catch((error) => { console.log(error); });
            // .finally();
    },
    testOfferAccepted() {
      this.checkcard.pop();
      this.checkcard.pop();

      // axios.post('http://localhost:3001/api/3po_in.php',
      axios.post('https://finchwright.com/api/3po_in.php',
                  { sub_type: 'onpl-test-offer-accepted-process', lpn: this.lpn, apiKey: apiKey },
                )
            .then((response) => {
              this.addCard(response.data);
              this.isLoading = false;
            })
            .catch((error) => { console.log(error); });
            // .finally();
    },
    testOfferRejected() {
      this.checkcard.pop();
      this.checkcard.pop();

      // axios.post('http://localhost:3001/api/3po_in.php',
      axios.post('https://finchwright.com/api/3po_in.php',
                  { sub_type: 'onpl-test-offer-declined-process', lpn: this.lpn, apiKey: apiKey },
                )
            .then((response) => {
              this.addCard(response.data);
              this.isLoading = false;
            })
            .catch((error) => { console.log(error); });
            // .finally();
    },
  }
}
</script>
