import { render, staticRenderFns } from "./OnplTests.vue?vue&type=template&id=0e6fecd4"
import script from "./OnplTests.vue?vue&type=script&lang=js"
export * from "./OnplTests.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports