<template>
  <div class="notification">
    <div class="content">
      <ul v-if="selectedDcs">
        <li v-for="dc in selectedDcs" :key="dc.dc">
          {{ dc.dc }} : {{ dc.carrier }}
        </li>
      </ul>
      <ul v-if="selectedSkus">
        <li v-for="sku in selectedSkus" :key="sku.sku">
          {{ sku.sku }}, {{ sku.final_price }}
        </li>
      </ul>
      <ul v-if="selectedLpns">
        <li v-for="lpn in selectedLpns" :key="lpn.lpn">
          {{ lpn.lpn }}, {{ lpn.sku }} ({{ lpn.wh }})
        </li>
      </ul>
      <section v-if="orderNotFound">
        <b-field label="Market Order Number">
          <b-input placeholder="Market Order number"
                  v-model="manualOrderNum">
          </b-input>
        </b-field>
        <b-field label="ChAd Order Number">
          <b-input placeholder="ChAd Order number"
                  v-model="manualChadOrderNumber">
          </b-input>
        </b-field>
        <b-field label="Marketplace">
          <b-select v-model='currentMarketplace' placeholder="Select a marketplace" >
            <option v-for="market in marketplaces" :key="market.message">{{ market.message }}</option>
          </b-select>
          <b-input v-if="currentMarketplace == 'Other'"
                   placeholder="Marketplace"
                   v-model="manualMarketplace">
          </b-input>
        </b-field>
        <b-field label="Shipped Date">
          <b-datetimepicker
              v-model="manualShipDate"
              placeholder="Select shipped date"
              icon="calendar-day"
              locale="en-CA">
          </b-datetimepicker>
            <b-button label="Clear"
                      type="is-secondary"
                      @click="manualShipDate = null" />
            <b-button label="Today"
                      type="is-secondary"
                      @click="manualShipDate = new Date()" />
        </b-field>
        <b-field label="SKU">
          <b-input placeholder="SKU"
                  v-model="sku">
          </b-input>
        </b-field>
        <b-field label="Final Cost">
          <b-input placeholder="Final Cost"
                  v-model="finalPrice">
          </b-input>
        </b-field>
      </section>
      <b-field label="Note Type">
          <b-select v-model='currentType' placeholder="Select a type" required @change="changeList">
            <option v-for="notetype in NoteTypes" :key="notetype.message">{{ notetype.message }}</option>
          </b-select>
      </b-field>
      <b-field>
          <b-select v-model='currentSelection' placeholder="Select a reason" required >
            <option v-for="reason in noteTypeReasons" :key="reason.message">{{ reason.message }}</option>
          </b-select>
      </b-field>
      <b-field v-if="currentType == 'Cancellation' || currentType == 'Partial-Cancellation'" label='Substituted?'>
          <b-select v-model='currentSubStatus' placeholder="Substituted?" required >
            <option v-for="status in noteSubStatuses" :key="status.message">{{ status.message }}</option>
          </b-select>
      </b-field>
      <b-field v-if="currentType == 'Return'" label='Replacement sent?'>
          <b-select v-model='currentReplaceStatus' placeholder="Replaced?" required >
            <option v-for="status in noteRepStatuses" :key="status.message">{{ status.message }}</option>
          </b-select>
      </b-field>
      <b-field v-if="currentType == 'Partial-Refund'" label='Partial-Refund Amount?'>
        <b-input v-model='currentRefundAmount' placeholder='$0.00'>
        </b-input>
      </b-field>
      <b-field v-if="currentSelection == 'Shipping Claim'" label='Carrier?'>
          <b-select v-model='currentCarrier' placeholder="Carrier?" required >
            <option v-for="carrier in carriers" :key="carrier.message">{{ carrier.message }}</option>
          </b-select>
      </b-field>
      <b-field v-if="currentSelection == 'Shipping Claim'" label='Claim Number?'>
        <b-input v-model='claimNumber' placeholder='Claim number?'>
        </b-input>
      </b-field>
      <b-field v-if="currentType == 'Refund'" label='Refund Amount?'>
        <b-input v-model='currentRefundAmount' placeholder='$0.00'>
        </b-input>
      </b-field>
      <b-field label="Notes">
          <b-input maxlength="500" type="textarea" v-model='noteText'></b-input>
      </b-field>
      <!-- <b-field>
        <b-button type="is-primary">Submit</b-button>
      </b-field> -->
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { apiKey } from "@/../api/api.json";

export default {
  props: {
    orderNotFound: Boolean,
    isReady: Boolean,
    isSubmitting: Boolean,
    submissionType: String,
    orderNum: String,
    secondaryOrderNumber: String,
    chadOrderNumber: String,
    warehouse: Object,
    marketplace: String,
    shipDate: String,
    selectedSkus: Array,
    selectedDcs: Array,
    selectedLpns: Array,
  },
  data() {
    return {
      NoteTypes: [
        { message: 'Return' },
        { message: 'Partial-Refund' },
        { message: 'Cancellation' },
        { message: 'Partial-Cancellation' },
        { message: 'Refund' },
      ],
      RetReasons: [
        { message: 'Accessory' },
        { message: 'Account Locked' },
        { message: 'Activation Fail' },
        { message: 'Bad Battery' },
        { message: 'Blacklisted' },
        { message: 'Buyers Remorse' },
        { message: 'Cosmetic Condition' },
        { message: 'Function Fail' },
        { message: 'Listing Error' },
        { message: 'Missing Parts' },
        { message: 'Other' },
        { message: 'Shipping Issue' },
        { message: 'Shipping Mixup' },
        { message: 'SKU Creation Error' },
        { message: 'Wrong Item' },
      ],
      PartialCancelReasons: [
        { message: 'Oversell' },
        { message: 'Location Issue' },
        { message: 'Mapping Error' },
        { message: 'Other' },
        { message: 'SKU Creation Error' },
        { message: 'Training Error' },
      ],
      CancelReasons: [
        { message: 'Buyer Cancel' },
        { message: 'Oversell' },
        { message: 'Location Issue' },
        { message: 'Mapping Error' },
        { message: 'Fraud' },
        { message: 'Undeliverable Address' },
        { message: 'Other' },
        { message: 'SKU Creation Error' },
        { message: 'Training Error' },
      ],
      PartialRefundReasons: [
        { message: 'Accessory' },
        { message: 'Activation Fail' },
        { message: 'Bad Battery' },
        { message: 'Cosmetic Condition' },
        { message: 'Function Fail' },
        { message: 'Listing Error' },
        { message: 'Missing Parts' },
        { message: 'Other' },
        { message: 'Shipping Issue' },
        { message: 'SKU Creation Error' },
        { message: 'Shipping Mixup' },
        { message: 'Wrong Item' },
      ],
      RefundReasons: [
        { message: 'Carrier RTS' },
        { message: 'Other' },
        { message: 'PayPal ' },
        { message: 'Received/No Entry' },
        { message: 'Shipping Claim' },
      ],
      noteSubStatuses: [
        { message: 'No' },
        { message: 'Yes' },
      ],
      noteRepStatuses: [
        { message: 'No' },
        { message: 'Yes' },
      ],
      carriers: [
        { message: 'FedEx' },
        { message: 'UPS' },
        { message: 'USPS' },
      ],
      marketplaces: [
        { message: 'Backmarket - CSC' },
        { message: 'Swappa' },
        { message: 'Other' },
      ],
      // noteTypeReasons: [],
      currentType: null,
      currentSelection: null,
      currentSubStatus: 'No',
      currentReplaceStatus: 'No',
      currentRefundAmount: null,
      currentCarrier: null,
      claimNumber: null,
      noteText: '',
      sku: null,
      finalPrice: null,
      currentMarketplace: null,
      manualOrderNum: this.orderNum,
      manualChadOrderNumber: this.chadOrderNumber,
      manualShipDate: this.shipDate,
      manualMarketplace: this.marketplace,

    }
  },
  computed: {
    // Reasons: [],
    noteTypeReasons: function () {
      // return this.NoteTypeList[this.currentType];
      return this.changeList();
    }
  },
  mounted() {
    this.currentType = this.submissionType;
    // this.noteTypeReasons = this.NoteTypeList[this.currentType];
    this.changeList();
    // if(this.submissionType == 'Cancellation'){
    //   this.noteTypeReasons = this.CancelReasons;
    // } else if(this.submissionType == 'Return') {
    //   this.noteTypeReasons = this.RetReasons;
    // } else if(this.submissionType == 'Partial-Cancellation') {
    //   this.noteTypeReasons = this.PartialReasons;
    // }
  },
  methods: {
    changeList() {
      // this.noteTypeReasons.splice();
      switch(this.currentType){
        case 'Return': this.currentSelection= null; return this.RetReasons;
        case 'Partial-Refund': this.currentSelection= null; return this.PartialRefundReasons;
        case 'Cancellation': this.currentSelection= null; return this.CancelReasons;
        case 'Partial-Cancellation': this.currentSelection= null; return this.PartialCancelReasons;
        case 'Refund': this.currentSelection= null; return this.RefundReasons;
      }
    },
  },
  watch: {
    isSubmitting: function (newValue) {
      // console.log('Submission says');
      // console.log(this.currentSelection);
      // console.log(this.noteText);
      // var skus = [];
      // this.selectedSkus.forEach((item) => {
      //   skus.push(item.sku);
      // });
      // skus = JSON.stringify(skus);
      // var dcs = [];
      // this.selectedDcs.forEach((item) => {
      //   dcs.push(item.dc);
      // });
      // dcs = JSON.stringify(dcs);
      if(newValue){
        if(this.currentMarketplace != 'Other'){
          this.manualMarketplace = this.currentMarketplace;
        }

        // axios.post('http://localhost:3001/api/api_in.php',
        axios.post('https://finchwright.com/api/api_in.php',
                  { type: 'submit',
                    orderNum: this.orderNum,
                    secondaryOrderNumber: this.secondaryOrderNumber,
                    chadOrderNumber: this.chadOrderNumber,
                    warehouse: this.selectedDcs,
                    marketplace: this.marketplace,
                    shipDate: this.shipDate,
                    noteType: this.currentType,
                    noteTag: this.currentSelection,
                    substituted: this.currentSubStatus,
                    replaced: this.currentReplaceStatus,
                    refund: this.currentRefundAmount,
                    currentCarrier: this.currentCarrier,
                    claimNumber: this.claimNumber,
                    noteText: this.noteText,
                    // selectedSkus: skus,
                    // selectedDcs: dcs,
                    selectedSkus: this.selectedSkus,
                    // selectedDcs: this.selectedDcs,
                    selectedLpns: this.selectedLpns,
                    orderNotFound: this.orderNotFound,
                    sku: this.sku,
                    finalPrice: this.finalPrice,
                    manualOrderNum: this.manualOrderNum,
                    manualChadOrderNumber: this.manualChadOrderNumber,
                    manualShipDate: this.manualShipDate,
                    manualMarketplace: this.manualMarketplace,
                    user: this.$auth.user.name,
                    apiKey: apiKey })
            .then((response) => {
              // console.log("Axios part");
              // console.log(response.data);
              if(response.data.attempted > 0){
                this.$emit('remove');
              } else {
                this.$emit('failed');
              }
            })
            .catch((error) => { console.log(error); });
      }
    }
  },
}
</script>
