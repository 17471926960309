import Vue from 'vue';
import App from './App.vue';
import router from './router';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas);
library.add(faUserSecret);

Vue.component('font-awesome-icon', FontAwesomeIcon);

import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
Vue.use(Buefy, { defaultIconPack: 'fas', defaultIconComponent: 'font-awesome-icon' });

import AuthenticationButton from '@/components/AuthenticationButton.vue';
import NavBar from '@/components/NavBar';

// Import the Auth0 configuration
import { domain, clientId } from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin } from "@/auth";

// import dymo from "@/DYMO.Label.Framework.latest.js";
// Object.definePrototype(Vue.prototype, '$dymo', { value: dymo });


// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
    );
  }
});

Vue.component('AuthenticationButton', AuthenticationButton);
Vue.component('NavBar', NavBar);

// const plugin = document.createElement("script");
// plugin.setAttribute(
//   "src",
//   // "//api.myplugincom/widget/mykey.js"
//   "//labelwriter.com/software/dls/sdk/js/DYMO.Label.Framework.latest.js"
// );
// plugin.async = true;
// document.head.appendChild(plugin);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
