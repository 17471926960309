<template>
  <section>
    <b-collapse
            class="card" 
            animation="slide" 
            aria-id="contentIdForA11y3">
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button"
                    aria-controls="contentIdForA11y3">
                    <p class="card-header-title">
                        {{ table_title }}
                    </p>
                    <b-field>
                      <b-button @click="download" type="is-primary">Download</b-button>
                    </b-field>
                    <a class="card-header-icon">
                      <b-icon
                          :icon="props.open ? 'minus' : 'plus'">
                      </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
              <div class="content">
                <b-table v-if="current_data.length"
                        :data="current_data"
                        :columns="columns"
                        scrollable
                        striped
                        hoverable>
                </b-table>
              </div>
            </div>
    </b-collapse>
  </section>
</template>

<script>
import Papa from "papaparse"

export default {
  // components:{
  //   // OrderReturnForm,
  //   // OrderCancelForm,
  //   // OrderNoteForm,
    // OrderInfo,
  //   // OrderSkuList
  // },
  props: {
    // data_array: Array,
    // order_data: Object,
    orders: Array,
    cancellations: Array,
    returns: Array,
    // value: Object,
    // data_type: String,
  },
  data() {
    return {
      table_title: null,
      current_data: [],
      columns: [],
      order_columns: [
        { field: 'idorder_return_cancel_notes', label: 'idorder_return_cancel_notes' },
        { field: 'market_order_number', label: 'market_order_number' },
        { field: 'note_tag', label: 'note_tag' },
        { field: 'notes', label: 'notes' },
        { field: 'note_type', label: 'note_type' },
        { field: 'chad_order_id', label: 'chad_order_id' },
        { field: 'second_order_id', label: 'second_order_id' },
        { field: 'date', label: 'date' },
        { field: 'skus', label: 'skus' },
        { field: 'user', label: 'user' },
        { field: 'warehouse', label: 'warehouse' },
        { field: 'substituted', label: 'substituted' },
        { field: 'replaced', label: 'replaced' },
        { field: 'marketplace', label: 'marketplace' },
        { field: 'ship_date', label: 'ship_date' },
        { field: 'refund', label: 'refund' },
        { field: 'carrier', label: 'carrier' },
        { field: 'claim_number', label: 'claim_number' },
        { field: 'unit_price', label: 'unit_price' },
        { field: 'final_unit_price', label: 'final_unit_price' },
      ],
      shipped_columns:  [
        { field: 'orderId', label: 'orderId' },
        { field: 'orderNumber', label: 'orderNumber' },
        { field: 'orderKey', label: 'orderKey' },
        { field: 'orderDate', label: 'orderDate' },
        { field: 'createDate', label: 'createDate' },
        { field: 'modifyDate', label: 'modifyDate' },
        { field: 'paymentDate', label: 'paymentDate' },
        { field: 'orderStatus', label: 'orderStatus' },
        { field: 'orderTotal', label: 'orderTotal' },
        { field: 'amountPaid', label: 'amountPaid' },
        { field: 'carrierCode', label: 'carrierCode' },
        { field: 'serviceCode', label: 'serviceCode' },
        { field: 'shipDate', label: 'shipDate' },
        { field: 'storeId', label: 'storeId' },
        { field: 'customField1', label: 'customField1' },
        { field: 'customField2', label: 'customField2' },
        { field: 'customField3', label: 'customField3' },
        { field: 'source', label: 'source' },
        { field: 'update_date', label: 'update_date' },
      ]
    }
  },
  // computed: {
  //   submissionType: function () {
  //     if(this.is_shipped){
  //       return 'Return';
  //     } else {
  //       return 'Cancellation';
  //     }
  //   }
  // },
  methods: {
    onCardSubmit() {
      // this.isReady = true;
      // if(this.is_shipped == true){
      //   this.readyReturn = true;
      // } else {
      //   this.readyCancel = true;
      // }
      // console.log(JSON.stringify(this.selected_skus))
    },
    download() {
      // Making the filename
      var date = new Date();
      var str = date.getFullYear()
              + ("0" + (date.getMonth() + 1)).slice(-2)
              + ("0" + date.getDate()).slice(-2);
      let filename = "finchreport_"+ str + ".csv";
      // credit: https://www.bitdegree.org/learn/javascript-download
      // via: https://www.raymondcamden.com/2020/12/15/vue-quick-shot-downloading-data-as-a-file
      let text = Papa.unparse(this.current_data);

      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);

    },
  },
  mounted() {
    if(this.cancellations){
      this.table_title = "Cancellations";
      this.columns = this.order_columns;
      this.current_data = this.cancellations;
    } else if(this.returns){
      this.table_title = "Returns";
      this.columns = this.order_columns;
      this.current_data = this.returns;
    } else if(this.orders){
      this.table_title = "Shipped Orders";
      this.columns = this.shipped_columns;
      this.current_data = this.orders;
    } 
  }
}
</script>
