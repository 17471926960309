<template>
  <div class="reports">
    <h1>Report Generation</h1>
    <hr>
    <section>
      <b-tabs v-model="activeTab">
        <b-tab-item label="Specific Search">
          <b-field label="LPN / Order Number">
            <b-input
                @keyup.native.enter="onSubmit"
                placeholder="Search..."
                type="search"
                icon="search"
                v-model="lpn">
            </b-input>
            <b-button refs="loadable" @click="onSubmit" type="is-primary" label="Search" />
            <b-notification
              auto-close type="is-danger"
              v-model="lpnNotFound"
              aria-close-label="Close notification">
              Lpn not found.
            </b-notification>
          </b-field>
          <br>
          <div v-if="reportcard[0].length">
            <ReportCard v-for="card in reportcard[0]"
                        :key="card.id"
                        v-bind="card"
                        @remove='removeCard(0)'/>
          </div>
        </b-tab-item>
        <b-tab-item label="Ranged Search">
            <b-field label="Start Time">
              <b-datetimepicker
                  v-model="datetimeStart"
                  placeholder="Select start date"
                  icon="calendar-day"
                  locale="en-CA">

                <template #left>
                  <b-button
                      label="This morning"
                      type="is-primary"
                      icon-left="clock"
                      @click="date = new Date(); datetimeStart = new Date(date.setHours(0,0))" />
                </template>
              </b-datetimepicker>
                <b-button label="Clear"
                          type="is-secondary"
                          @click="datetimeStart = null" />
            </b-field>
            <b-field label="End Time">
              <b-datetimepicker
                  v-model="datetimeEnd"
                  placeholder="Select end date"
                  icon="calendar-day"
                  locale="en-CA">

                <template #left>
                  <b-button
                      label="Now"
                      type="is-primary"
                      icon-left="clock"
                      @click="datetimeEnd = new Date()" />
                </template>
              </b-datetimepicker>
                <b-button label="Clear"
                          type="is-secondary"
                          @click="datetimeEnd = null" />
            </b-field>
            
            <b-field label="Client">
              <b-select v-model='currentClient' placeholder="Select client" >
                <option v-for="client in clients" :key="client">{{ client }}</option>
              </b-select>
            </b-field>
            <b-field label="Marketplace">
              <b-select v-model='currentSource' placeholder="Select marketplace" >
                <option v-for="source in data_sources" :key="source">{{ source }}</option>
              </b-select>
            </b-field>
            <b-field label="Note Type">
                <b-select v-model='currentType' placeholder="Select a type" >
                  <option v-for="notetype in NoteTypes" :key="notetype.message">{{ notetype.message }}</option>
                </b-select>
            </b-field>
            <b-field>
              <b-select v-model='currentSelection' placeholder="Select a reason" >
                <option v-for="reason in noteTypeReasons" :key="reason.message">{{ reason.message }}</option>
              </b-select>
            </b-field>
            <b-field label='Substituted / Replaced?'>
                <b-select v-model='currentSubStatus' placeholder="Substituted / Replaced?" >
                  <option v-for="status in noteSubStatuses" :key="status.message">{{ status.message }}</option>
                </b-select>
            </b-field>
            <b-field label='Refund Tag'>
              <b-select v-model='currentRefundTag' placeholder="Refund Tag?" >
                <option v-for="refundtag in RefundTags" :key="refundtag.message">{{ refundtag.message }}</option>
              </b-select>
            </b-field>
            <b-field label='Next Process'>
              <b-select v-model='currentProcessTag' placeholder="Next Process?" >
                <option v-for="processtag in NextProcessTags" :key="processtag.message">{{ processtag.message }}</option>
              </b-select>
            </b-field>
          <b-button refs="loadable" @click="onSubmit" type="is-primary" label="Search" />
          <br>
          <div v-if="reportcard[1].length">
            <ReportCard v-for="card in reportcard[1]"
                        :key="card.id"
                        v-bind="card"
                        @remove='removeCard(1)'/>
          </div>
        </b-tab-item>
        <b-tab-item label="Predefined Search">
          <b-tabs>
            <b-tab-item label='IMEIs Shipped'>
              <EndOfDay />
            </b-tab-item>
            <b-tab-item v-if=false label='Client Performance'>
              <ClientPerformance />
            </b-tab-item>
            <b-tab-item v-if=false label='Outstanding Returns'>
              <OutstandingReturns />
            </b-tab-item>
          </b-tabs>
        </b-tab-item>
        <b-tab-item label='Upload'>
          <ClientOrderFileUpload />
        </b-tab-item>
      </b-tabs>
    </section>
    <br>

    <!-- <div v-if="reportcard.length">
      <ReportCard v-for="card in reportcard"
                  :key="card.id"
                  v-bind="card"
                  @remove='removeCard'/>
    </div> -->
  </div>
</template>

<script>
import ReportCard from "@/components/ReportCard.vue"
import EndOfDay from "@/views/EndOfDay.vue"
import OutstandingReturns from "@/views/OutstandingReturns.vue"
import ClientPerformance from "@/views/ClientPerformance.vue"
import ClientOrderFileUpload from "@/views/ClientOrderFileUpload.vue"
import axios from "axios"

import { apiKey } from "@/../api.json";

export default {
  components: {
    ReportCard,
    EndOfDay,
    OutstandingReturns,
    ClientPerformance,
    ClientOrderFileUpload,
  },
  data() {
    return {
      lpn: '',
      activeTab: 1,
      reportcard: [[],[],[]],
      lpnNotFound: false,
      datetimeStart: null,
      datetimeEnd: null,
      NextProcessTags: [
        { message: '' },
        { message: 'Reprocessing' },
        { message: 'Disposal' },
        { message: 'Hold for Customer' },
      ],
      RefundTags: [
        { message: '' },
        { message: 'Refunded' },
        { message: 'Early refund' },
        { message: 'Ready for refund' },
        { message: 'Pin-locked' },
        { message: 'Do not refund' },
        { message: 'Fraud' },
      ],
      NoteTypes: [
        { message: '' },
        { message: 'Return' },
        { message: 'Partial-Refund' },
        { message: 'Cancellation' },
        { message: 'Partial-Cancellation' },
      ],
      PartialRefundReasons: [
        { message: 'Activation Locked' },
        { message: 'AT&T Finance Locked' },
        { message: 'Bad Battery' },
        { message: 'Blacklisted' },
        { message: 'Buyer Remorse' },
        { message: 'Cosmetic Condition' },
        { message: 'Financed' },
        { message: 'Function Fail' },
        { message: 'Late Delivery' },
        { message: 'Listing Issue' },
        { message: 'Lost/Stolen' },
        { message: 'Missing Accessory' },
        { message: 'Non-Delivery' },
        { message: 'Wrong Carrier' },
        { message: 'Other' }
      ],
      RetReasons: [
        { message: '' },
        { message: 'Activation Locked' },
        { message: 'AT&T Finance Locked' },
        { message: 'Bad Battery' },
        { message: 'Blacklisted' },
        { message: 'Buyer Remorse' },
        { message: 'Cosmetic Condition' },
        { message: 'Financed' },
        { message: 'Function Fail' },
        { message: 'Listing Issue' },
        { message: 'Lost/Stolen' },
        { message: 'Non-Delivery' },
        { message: 'Wrong Carrier' },
        { message: 'Other' }
      ],
      CancelReasons: [
        { message: '' },
        { message: 'Buyer Cancel' },
        { message: 'Inventory Issue' },
        { message: 'Oversell' },
        { message: 'System Issue' },
        { message: 'Undeliverable Address' },
        { message: 'Other' }
      ],
      noteSubStatuses: [
        { message: '' },
        { message: 'Replaced' },
        { message: 'Substituted' },
      ],
      currentProcessTag: null,
      currentRefundTag: null,
      currentType: null,
      currentSelection: null,
      currentSubStatus: null,
      // currentReplaceStatus: null,
      currentClient: null,
      clients: [],
      currentSource: null,
      data_sources: [],
    }
  },
  computed: {
    // Reasons: [],
    noteTypeReasons: function () {
      // return this.NoteTypeList[this.currentType];
      return this.changeList();
    }
  },
  methods: {
    changeList: function() {
      // this.noteTypeReasons.splice();
      switch(this.currentType){
        case 'Return': this.currentSelection= null; return this.RetReasons;
        case 'Partial-Refund': this.currentSelection= null; return this.PartialRefundReasons;
        case 'Cancellation': this.currentSelection= null; return this.CancelReasons;
        case 'Partial-Cancellation': this.currentSelection= null; return this.CancelReasons;
      }
    },
    addCard: function(entry, tab) {
      // console.log("Card part");
      // console.log(entry)
      if(entry.lpn_data && entry.lpn_data.length){
        // this.reportcard.pop();
        var test_a = { lpn_data: entry.lpn_data };
        this.reportcard[tab].push(test_a);
      }
      if(entry.order_data && entry.order_data.length){
        // this.reportcard.pop();
        var test_b = { order_data: entry.order_data };
        this.reportcard[tab].push(test_b);
      }
      if(entry.date_data && entry.date_data.length){
        // this.reportcard.pop();
        var test_c = { order_data: entry.date_data };
        this.reportcard[tab].push(test_c);
      }
      // else {
      //   this.lpnNotFound = true;
      // }
    },
    addClients: function(entry) {
      this.clients = [''];
      this.clients = this.clients.concat(entry.clients);
      this.data_sources = [''];
      this.data_sources = this.data_sources.concat(entry.data_sources);
      // this.clients = entry.clients;
      // this.clients.push('');
      // this.data_sources = entry.data_sources;
      // this.data_sources.push('');
    },
    removeCard: function(tab) {
      this.reportcard[tab].pop();
      this.reportcard[tab].pop();
      this.reportcard[tab].pop();
      this.lpn = '';
    },
    onSubmit: function() {
      var current_tab = this.activeTab;
      this.lpnNotFound = false;
      const loadingComponent = this.$buefy.loading.open({
                    container: this.$refs.loadable
                });
      this.reportcard[current_tab].pop();
      this.reportcard[current_tab].pop();
      this.reportcard[current_tab].pop();
      // console.log('Order number submitted: ', this.lpn)
      // const axios = require('axios').default;
      var subcheck = this.currentSubStatus == 'Substituted' ? "Yes" : null;
      var repcheck = this.currentSubStatus == 'Replaced' ? "Yes" : null;

      // axios.post('http://localhost:3001/api/api_in.php',
      axios.post('https://finchwright.com/api/api_in.php',
                 { type: 'reportsearch',
                   lpn: this.lpn,
                   dateStart: this.datetimeStart,
                   dateEnd: this.datetimeEnd,
                   currentProcessTag: this.currentProcessTag,
                   currentRefundTag: this.currentRefundTag,
                   currentType: this.currentType,
                   currentSelection: this.currentSelection,
                   currentSubStatus: subcheck,
                   currentReplaceStatus: repcheck,
                   currentClient: this.currentClient,
                   currentSource: this.currentSource,
                   apiKey: apiKey },
                 // { headers: { Authorization: `Bearer ${this.apiKey}` } }
                )
           .then((response) => {
             // console.log("Axios part");
             // console.log(response.data);
             // this.orderentry = response.data;
             this.addCard(response.data, current_tab);
             loadingComponent.close();
           })
           .catch((error) => { console.log(error); });
           // .finally();
    },
  },
  mounted() {
    const loadingComponent = this.$buefy.loading.open({ container: this.$refs.loadable });
    // axios.post('http://localhost:3001/api/api_in.php',
    axios.post('https://finchwright.com/api/api_in.php',
              { type: 'getclients',
                apiKey: apiKey },
              // { headers: { Authorization: `Bearer ${this.apiKey}` } }
            )
        .then((response) => {
          // console.log("Axios part");
          // console.log(response.data);
          // this.orderentry = response.data;
          loadingComponent.close();
          this.addClients(response.data);
        })
        .catch((error) => { console.log(error); });
        // .finally();
  },
}
</script>
