<template>
  <div class="reports">
    <h1>3PO</h1>
    <hr>
    <section>
      <b-tabs v-model="activeTab">
        <b-tab-item label='R3PO'>
          <R3po />
        </b-tab-item>
        <b-tab-item label='reZed'>
          <ReZed />
        </b-tab-item>
        <b-tab-item label='Receive POs'>
          <ReceivePOs />
        </b-tab-item>
        <b-tab-item label='LPN Check'>
          <LPNCheck />
        </b-tab-item>
        <b-tab-item label='QC File Upload'>
          <!-- <QCFileUpload /> -->
        </b-tab-item>
      </b-tabs>
    </section>
    <br>
  </div>
</template>

<script>
import R3po from "@/views/R3poView.vue"
import ReZed from "@/views/ReZed.vue"
import ReceivePOs from "@/views/ReceivePOs.vue"
import LPNCheck from "@/views/LPNCheck.vue"
// import QCFileUpload from "@/views/QCFileUpload.vue"

export default {
  components: {
    R3po,
    ReZed,
    ReceivePOs,
    LPNCheck,
    // QCFileUpload,
  },
  data() {
    return {
      activeTab: 1,
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
