<template>
  <div class="card">
    <div class="card-content">

      <!-- <div class="content">
        <order-info v-for="item in info"
                    v-bind:key="item.id"
                    v-bind="item"/>
      </div> -->
      <section>
        <b-table :data="current_data"
                 :columns="columns">
        </b-table>
      </section>
        <!-- <OrderNoteForm :isReady="isReady"
                       :orderNum='market_order_number'
                       :secondaryOrderNumber='secondary_order_number'
                       :chadOrderNumber='chad_order_number'
                       :shipDate = 'ship_date'
                       :selectedSkus='selected_skus'
                       :submissionType='submissionType'
                       @remove="$emit('remove', idorders)"/> -->
      <br>
      <!-- <b-field>
        <b-button @click="onCardSubmit" type="is-primary">Submit</b-button>
      </b-field> -->
    </div>
  </div>
</template>

<script>
// import OrderReturnForm from "./OrderReturnForm.vue"
// import OrderCancelForm from "./OrderCancelForm.vue"
// import OrderNoteForm from "./OrderNoteForm.vue"
// import OrderInfo from "./OrderInfo.vue"
// import OrderSkuList from "./OrderSkuList.vue"

export default {
  // components:{
  //   // OrderReturnForm,
  //   // OrderCancelForm,
  //   // OrderNoteForm,
  //   // OrderInfo,
  //   // OrderSkuList
  // },
  props: {
    // data_array: Array,
    po: String,
    lpns: Array,
    order_data: Array,
    // data_type: String,
  },
  data() {
    return {
      current_data: [],
      columns: [],
      lpn_columns: [
        { field: 'scanned',label: 'Scanned' },
        { field: 'lpn',label: 'LPN' },
        { field: 'sku',label: 'SKU' },
        { field: 'entry_tag',label: 'Entry Tag' },
      ],
    }
  },
  // computed: {
  //   submissionType: function () {
  //     if(this.is_shipped){
  //       return 'Return';
  //     } else {
  //       return 'Cancellation';
  //     }
  //   }
  // },
  methods: {
    onCardSubmit() {
      // this.isReady = true;
      // if(this.is_shipped == true){
      //   this.readyReturn = true;
      // } else {
      //   this.readyCancel = true;
      // }
      // console.log(JSON.stringify(this.selected_skus))
    }
  },
  mounted() {
    this.columns = this.lpn_columns;
    this.current_data = this.lpns;
    // if(this.lpn_data){
    //   this.columns = this.lpn_columns;
    //   this.current_data = this.lpn_data;
    // } else if(this.order_data) {
    //   this.columns = this.order_columns;
    //   this.current_data = this.order_data;
    // }
  }
}
</script>
