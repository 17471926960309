<template>
  <div v-if="infolist.length">
    <SellerCloudCheckInfo v-for="info in infolist"
                  :key="info.id"
                  v-bind="info"/>
  </div>
</template>

<script>
import SellerCloudCheckInfo from "@/components/SellerCloudCheckInfo.vue"

export default {
  components: {
    SellerCloudCheckInfo,
  },
  props: {
    checkinfo: Object,
  },
  data() {
    return {
      infolist: [],
    }
  },
  mounted(){
    var id = 0;
    Object.keys(this.checkinfo).forEach(key => {
      var ent = { id: id++, label: key, value: JSON.stringify(this.checkinfo[key]) }
      // this.infolist.push(ent);
      this.infolist.unshift(ent);
    });
  }
}
</script>
