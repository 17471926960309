<template>
  <div class="end-of-day">
    <h1>IMEIs Shipped</h1>
    <hr>
      <section>
        <!-- <b-field label="Which Marketplace?">
          <b-select v-model='currentSource' placeholder="Select source" >
            <option v-for="source in data_sources" :key="source">{{ source }}</option>
          </b-select>
        </b-field> -->
        <b-field label="Start Time">
          <b-datetimepicker
              v-model="datetimeStart"
              placeholder="Select start date"
              icon="calendar-day"
              locale="en-CA">
          </b-datetimepicker>
            <b-button label="Clear"
                      type="is-secondary"
                      @click="datetimeStart = null" />
            <b-button label="Today"
                      type="is-secondary"
                      @click="date = new Date(); datetimeStart = new Date(date.setHours(0,0))" />
        </b-field>
        <b-field label="End Time">
          <b-datetimepicker
              v-model="datetimeEnd"
              placeholder="Select end date"
              icon="calendar-day"
              locale="en-CA">
          </b-datetimepicker>
            <b-button label="Clear"
                      type="is-secondary"
                      @click="datetimeEnd = null" />
            <b-button label="Today"
                      type="is-secondary"
                      @click="datetimeEnd = new Date()" />
        </b-field>
      </section>
      <b-button refs="loadable" @click="onSubmit" type="is-primary" label="Search" />
    <br>

    <div v-if="cards.length">
      <IMEIsCard v-for="card in cards"
                 :key="card.id"
                 v-bind="card"
                 @remove='removeCard'/>
    </div>
  </div>
</template>

<script>
import IMEIsCard from "@/components/IMEIsCard.vue"
import axios from "axios"

import { apiKey } from "@/../api/api.json";

export default {
  components: {
    IMEIsCard,
  },
  data() {
    return {
      datetimeStart: null,
      datetimeEnd: null,
      cards: [],
      orderNotFound: false,
      // currentSource: 'BackMarket US',
      // data_sources: [ 'BackMarket US','BackMarket France' ],
    }
  },
  methods: {
    addCard: function(entry) {
      // console.log("Card part");
      // console.log(entry)
      // if(entry.market_order_number || entry.second_order_id || entry.chad_order_id){
        this.cards.pop();
        this.cards.push(entry);
      // } else {
      //   this.orderNotFound = true;
      // }
    },
    // addCards: function(entry) {
    //   // entry.imei_data.forEach(element => this.addCard(element));
    //   // var arr = Array.from(entry.imei_data);
    //   // arr.forEach(element => this.addCard(element));
    //   // console.log(entry.imei_data);
    //   // for(key in entry.imei_data){ console.log(key, entry.imei_data[key]); }
    //   // for(var [key,val] of Object.entries(entry.imei_data)){ this.addCard(val); console.log(`${key}`); }
    //   // console.log(Object.entries(entry.imei_data));
    //   // for(var i = 0; i < entry.imei_data.length; i++){
        
    //   // }
    // },
    removeCard: function() {
      this.cards.pop();
      this.ordernum = '';
    },
    onSubmit() {
      this.orderNotFound = false;
      const loadingComponent = this.$buefy.loading.open({
                    container: this.$refs.loadable
                });
      this.cards.pop();
      // console.log('Order number submitted: ', this.ordernum)
      // const axios = require('axios').default;

      // axios.post('http://localhost:3001/api/api_in.php',
      axios.post('https://finchwright.com/api/api_in.php',
                 { type: 'endofday', source: this.currentSource, datetimeStart: this.datetimeStart, datetimeEnd: this.datetimeEnd, apiKey: apiKey },
                 // { headers: { Authorization: `Bearer ${this.apiKey}` } }
                )
           .then((response) => {
             // console.log("Axios part");
             // console.log(response.data);
             // this.orderentry = response.data;
             this.addCard(response.data);
            //  this.addCards(response.data);
             loadingComponent.close();
           })
           .catch((error) => { console.log(error); });
           // .finally();
    },
  }
}
</script>
