<template>
  <div>
    <h1>Client Order File Upload</h1>
    <b-field>
      <b-select v-model='currentClient' placeholder="Select client" required>
        <option v-for="client in clients" :key="client">{{ client }}</option>
      </b-select>
    </b-field>
    <b-field>
      <b-select v-model='currentSource' placeholder="Select data source" required>
        <option v-for="source in data_sources" :key="source">{{ source }}</option>
      </b-select>
    </b-field>
    <b-field class="file is-primary" :class="{'has-name': !!file}">
      <b-upload v-model="file" class="file-label">
        <span class="file-cta">
          <b-icon class="file-icon" icon="upload"></b-icon>
          <span class="file-label">Choose File</span>
        </span>
        <span class="file-name" v-if="file">
          {{ file.name }}
        </span>
      </b-upload>
      <b-button refs="loadable" @click="onSubmit" type="is-primary" label="Upload" :disabled="!isReady"/>
    </b-field>
  </div>
</template>

<script>
import axios from "axios"

import { apiKey } from "@/../api/api.json";

export default {
  data() {
    return {
      file: null,
      currentClient: null,
      clients: [],
      currentSource: null,
      data_sources: [],
    }
  },
  computed: {
    isReady: function () {
      return (this.currentClient != null && this.currentSource != null && this.file != null); 
    },
  },
  methods: {
    onSubmit() {
      if(this.currentClient != null && this.currentSource != null && this.file != null){
        const loadingComponent = this.$buefy.loading.open({ container: this.$refs.loadable });

        const formData = new FormData();
        formData.append('file',this.file);
        formData.append('type','clientorderupload');
        formData.append('client',this.currentClient);
        formData.append('data_source',this.currentSource);
        formData.append('apiKey',apiKey);


        // console.log('Order number submitted: ', this.lpn)
        // const axios = require('axios').default;

        // const reader = new FileReader();
        // reader.onload = function (event) {
        //   console.log(event.target.result); // the CSV content as string
        // };
        // reader.readAsArrayBuffer(this.file);

        // axios.post('http://localhost:3001/api/upload_in.php',
        axios.post('https://finchwright.com/api/upload_in.php',
                  formData,
                  { headers: { 'Content-Type': 'multipart/form-data' } }
                  //  { sub_type: 'qc-trans-submit', file: formData, apiKey: apiKey },
                   // { headers: { Authorization: `Bearer ${this.apiKey}` } }
                  )
             .then((response) => {
               console.log("Axios part");
               console.log(response.data);
               // this.orderentry = response.data;
              //  this.addCard(response.data);
               loadingComponent.close();
              //  this.isLoading = false;
             })
             .catch((error) => { console.log(error); });
             // .finally();
      } else {
        console.log(this.file_arr.length);
      }
    },
    addClients: function(entry) {
      this.clients = entry.clients;
      this.data_sources = entry.data_sources;
    },
  },
  mounted() {
    const loadingComponent = this.$buefy.loading.open({ container: this.$refs.loadable });
    // axios.post('http://localhost:3001/api/api_in.php',
    axios.post('https://finchwright.com/api/api_in.php',
              { type: 'getclients',
                apiKey: apiKey },
              // { headers: { Authorization: `Bearer ${this.apiKey}` } }
            )
        .then((response) => {
          // console.log("Axios part");
          // console.log(response.data);
          // this.orderentry = response.data;
          loadingComponent.close();
          this.addClients(response.data);
        })
        .catch((error) => { console.log(error); });
        // .finally();
  },
}
</script>
