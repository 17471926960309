<template>
  <div class="reports">
    <h1>SellerCloud</h1>
    <hr>
    <section>
      <b-tabs v-model="activeTab">
        <b-tab-item label='SellerCloud Intake'>
          <SellerCloudIntake />
        </b-tab-item>
        <b-tab-item label='SellerCloud ASN Upload'>
          <SellerCloudASNUpload />
        </b-tab-item>
      </b-tabs>
    </section>
    <br>
  </div>
</template>

<script>
import SellerCloudIntake from "@/views/SellerCloudIntake.vue"
import SellerCloudASNUpload from "@/views/SellerCloudASNUpload.vue"

export default {
  components: {
    SellerCloudIntake,
    SellerCloudASNUpload,
  },
  data() {
    return {
      activeTab: 0,
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
