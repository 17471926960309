<template>
  <div class="r3po">
    <h1>reZed</h1>
    <hr>
      <section>
        <b-field label="LPNs">
            <b-input type="textarea" v-model='lpn_list'></b-input>
        </b-field>
        <b-field>
            <b-select v-model='currentSelection' placeholder="Select type" required>
              <option v-for="submission_type in SubmissionTypes" :key="submission_type.message">
                {{ submission_type.message }}
              </option>
            </b-select>
        </b-field>
        <b-button refs="loadable" @click="onSubmit" type="is-primary" label="Submit" />
      </section>
    <br>

    <div v-if="r3po_card.length">
      <R3poCard v-for="card in r3po_card"
                 :key="card.id"
                 v-bind="card"
                 @remove='removeCard'/>
    </div>
  </div>
</template>

<script>

import R3poCard from "@/components/R3poCard.vue";
import axios from "axios";

import { apiKey } from "@/../api/api.json";

export default {
  components: {
    R3poCard,
  },
  data() {
    return {
      lpn_list: '',
      r3po_card: [],
      currentSelection: null,
      SubmissionTypes: [
        { message: 'rezed' },
        { message: 'rec3po' },
      ],
    }
  },
  methods: {
    addCard: function(entry) {
      // console.log("Card part");
      // console.log(entry)
      this.r3po_card.push(entry);
    },
    removeCard: function() {
      this.r3po_card.pop();
    },
    processUploadResponse(data) {
      data.forEach((entry) => { this.addCard(entry) });
    },
    onSubmit() {
      this.orderNotFound = false;
      const loadingComponent = this.$buefy.loading.open({
                    container: this.$refs.loadable
                });
      this.r3po_card.pop();
      // console.log('Order number submitted: ', this.input)
      // const axios = require('axios').default;

      // axios.post('http://localhost:3001/api/3po_in.php',
      axios.post('https://finchwright.com/api/3po_in.php',
                  { sub_type: 'rezed',
                    lpns: this.lpn_list,
                    type: this.currentSelection,
                    apiKey: apiKey, },
                 // { headers: { Authorization: `Bearer ${this.apiKey}` } }
                )
           .then((response) => {
             // console.log("Axios part");
             // console.log(response.data);
             // this.orderentry = response.data;
             this.addCard(response.data);
             loadingComponent.close();
           })
           .catch((error) => { console.log(error); });
           // .finally();
    },
  },
  mounted() {
  }
}
</script>
