<template>
  <div :class="isExpired ? 'notification is-danger has-icon columns':'columns'">
    <span class='column is-one-third'>{{ label }}</span>
    <span class='column'>{{ value }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: String,
    isExpired: Boolean,
  },
}
</script>
