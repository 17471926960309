<template>
  <a 
    class="button is-primary" 
    v-if="$auth.isAuthenticated" 
    @click.prevent="logout"
  > Log out
  </a>
</template>

<script>
  export default {
    name: 'LogoutButton', 
    methods: {
      logout() {
        this.$auth.logout();
        this.$router.push({ path: '/' });
      },
    },
  };
</script>