<template>
  <div class="sellercloud-intake">
    <h1>SellerCloud Intake</h1>
    <hr>
    <section>
      <b-field label="Printer" label-for="printer-select">
        <b-select id="printer-select" v-model='currentPrinter' placeholder="Select printer" >
          <option v-for="printer in printers" :key="printer">
            {{ printer }}
          </option>
        </b-select>
        <b-button refs="loadable" @click="refreshPrinters" type="is-primary" label="Get Printers" />
      </b-field>
      <b-field label="Bin" label-for="bin-input">
        <section>
          <b-input
              id="bin-input"
              placeholder="Bin..."
              v-model="bin">
          </b-input>
        </section>
      </b-field>
      <b-field label="Identifier" label-for="identifier-input">
        <section>
          <b-input
              id="identifier-input"
              @keyup.native.enter="onSubmit"
              placeholder="Identifier..."
              type="search"
              icon="magnify"
              v-model="lpn"
              :disabled='disableSearch'>
          </b-input>
          <b-notification
            auto-close type="is-danger"
            v-model="lpnNotFound"
            aria-close-label="Close notification">
            Identifier not found.
          </b-notification>
        </section>
        <b-button refs="loadable" @click="onSubmit" type="is-primary" label="Search" :disabled='disableSearch' />
        <!-- <b-button refs="loadable" @click="testPrint" type="is-primary" label="Test Print" /> -->
      </b-field>
    </section>
    <br>

    <div v-if="checkcard.length">
      <SellerCloudCheckCard v-for="card in checkcard"
                            :key="card.id"
                            v-bind="card"
                            @remove='removeCard'/>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
  </div>
</template>

<script>
import SellerCloudCheckCard from "@/components/SellerCloudCheckCard.vue"
import axios from "axios"

import { apiKey } from "@/../api/api.json";

export default {
  components: {
    SellerCloudCheckCard,
  },
  data() {
    return {
      lpn: '',
      bin: '',
      printers: [],
      currentPrinter: '',
      label: '',
      checkcard: [],
      lpnNotFound: false,
      isLoading: false,
      disableSearch: false,
    }
  },
  methods: {
    addCard: function(entry) {
      console.log("Card part");
      console.log(entry)
      if(entry){
        var info = { id: 1, checkinfo: entry }
        this.checkcard.pop();
        this.checkcard.push(info);
        this.lpn = '';
      } else {
        this.lpnNotFound = true;
      }
    },
    removeCard: function() {
      this.checkcard.pop();
      this.checkcard.pop();
      this.lpn = '';
    },
    onSubmit() {
      if(this.lpn != ''){
        this.disableSearch = true;
        this.isLoading = true;
        this.lpnNotFound = false;
        // const loadingComponent = this.$buefy.loading.open({
        //               container: this.$refs.loadable.$el,
        //           });
        this.checkcard.pop();
        this.checkcard.pop();
        // console.log('Order number submitted: ', this.lpn)
        // const axios = require('axios').default;

        // axios.post('http://localhost:3001/api/3po_in.php',
        axios.post('https://finchwright.com/api/3po_in.php',
                   { sub_type: 'sellercloud-intake', identifier: this.lpn, bin: this.bin, apiKey: apiKey },
                   // { headers: { Authorization: `Bearer ${this.apiKey}` } }
                  )
             .then((response) => {
               // console.log("Axios part");
               // console.log(response.data);
               // this.orderentry = response.data;
               this.printSmallLabelMod(response.data.lpn);
               this.addCard(response.data);
               // loadingComponent.close();
               this.disableSearch = false;
               this.isLoading = false;
             })
             .catch((error) => { console.log(error);
                                 this.disableSearch = false;
                                 this.isLoading = false; });
             // .finally();
      }
    },
    printSmallLabelMod(str){
      // var printer = printersComboBox.value;
      try {
        this.label.setObjectText("QR", str);
        this.label.setObjectText("TEXT", str);
        this.label.print(JSON.parse(this.currentPrinter));//.name);
        
      } catch (e) {
        this.addCard(e.message);
        //outputLine(e.message || e);
      }
    },
    testPrint(){
      console.log("test:");
      console.log(this.currentPrinter);
      var temp_printer = JSON.parse(this.currentPrinter);//.name;
      console.log(temp_printer);
      this.printSmallLabelMod("TEST01234567");
    },
    refreshPrinters: function() {
      // this.printers = window.dymo.label.framework.getPrinters();
      console.log(this.printers);

      var printer_arr = window.dymo.label.framework.getPrinters();
      for (const entry of printer_arr){
        this.printers.push(entry.name);
      }
      this.currentPrinter = this.printers[0].name;
      // this.addCard(this.printers[0].name);
    }
  },
  mounted(){
    // this.printers = window.dymo.label.framework.getPrinters();
    // .then((response) => { 
    //   this.printers = response; 
    //   this.addCard(this.printers);
    // })
    // .catch((error) => { console.log(error); });
    // this.currentPrinter = this.printers[0];
    // this.addCard(this.this.printers[0].name);
    
    axios.get('https://finchwright.com/lpn_small.dymo')
         .then((response) => {
           this.label = window.dymo.label.framework.openLabelXml(response.data);
         })
         .catch((error) => { console.log(error); });
  }
}
</script>
