<template>
  <div class="reports">
    <h1>ONPL</h1>
    <hr>
    <section>
      <b-tabs v-model="activeTab">
        <b-tab-item label='ONPL Intake'>
          <OnplIntake />
        </b-tab-item>
        <b-tab-item label='ONPL Post-Inspection'>
          <OnplPostInspection />
        </b-tab-item>
        <b-tab-item label='ONPL Report'>
          <OnplReport />
        </b-tab-item>
        <b-tab-item label='ONPL Tests'>
          <OnplTests />
        </b-tab-item>
        <b-tab-item label='Print Test'>
          <!-- <PrintTest /> -->
        </b-tab-item>
      </b-tabs>
    </section>
    <br>
  </div>
</template>

<script>
import OnplIntake from "@/views/OnplIntake.vue"
import OnplPostInspection from "@/views/OnplPostInspection.vue"
import OnplReport from "@/views/OnplReport.vue"
import OnplTests from "@/views/OnplTests.vue"
// import PrintTest from "@/views/PrintTest.vue"

export default {
  components: {
    OnplIntake,
    OnplPostInspection,
    OnplReport,
    OnplTests,
    // PrintTest,
  },
  data() {
    return {
      activeTab: 2,
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
