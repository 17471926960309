<template>
  <a
    class="button is-primary" 
    v-if="!$auth.isAuthenticated" 
    @click="login"
  > Login 
  </a>
</template>

<script>
  export default {
    name: 'LoginButton',
    methods: {
      login() {
        this.$auth.loginWithRedirect();
      },
    },
  };
</script>