<template>
  <div class="customer-service">
    <h1>Order Return/Cancellation Note Submission</h1>
    <hr>
    <section>
      <b-field label="Order Number">
        <section>
          <b-input
              @keyup.native.enter="onSubmit"
              placeholder="Search..."
              type="search"
              icon="search"
              v-model="ordernum">
          </b-input>
          <b-notification
            auto-close type="is-danger"
            v-model="orderNotFound"
            aria-close-label="Close notification">
            Order not found.
          </b-notification>
        </section>
        <b-button refs="loadable" @click="onSubmit" type="is-primary" label="Search" />
      </b-field>
    </section>
    <br>

    <div v-if="ordercard.length">
      <OrderCard v-for="card in ordercard"
                 :key="card.id"
                 v-bind="card"
                 @remove='removeCard'/>
    </div>
  </div>
</template>

<script>
import OrderCard from "@/components/OrderCard.vue"
import axios from "axios"

import { apiKey } from "@/../api/api.json";

export default {
  components: {
    OrderCard,
  },
  data() {
    return {
      ordernum: '',
      ordercard: [],
      orderNotFound: false,
    }
  },
  methods: {
    addCard: function(entry) {
      // console.log("Card part");
      // console.log(entry)
      // if(entry.market_order_number || entry.second_order_id || entry.chad_order_id){
      if(typeof entry.chad_order_number !== 'undefined' || 
         typeof entry.sku_list !== 'undefined' || 
         typeof entry.lpn_list !== 'undefined'){
        // this.ordercard.pop();
        // this.ordercard.push(entry);
      } else {
         this.orderNotFound = true;
         entry.market_order_number = this.ordernum;
      }
      entry.orderNotFound = this.orderNotFound;
      this.ordercard.pop();
      this.ordercard.push(entry);
    },
    removeCard: function() {
      this.ordercard.pop();
      this.ordernum = '';
    },
    onSubmit() {
      this.orderNotFound = false;
      const loadingComponent = this.$buefy.loading.open({
                    container: this.$refs.loadable
                });
      this.ordercard.pop();
      // console.log('Order number submitted: ', this.ordernum)
      // const axios = require('axios').default;

      // axios.post('http://localhost:3001/api/api_in.php',
      axios.post('https://finchwright.com/api/api_in.php',
                 { type: 'search', orderNum: this.ordernum, apiKey: apiKey },
                 // { headers: { Authorization: `Bearer ${this.apiKey}` } }
                )
           .then((response) => {
             // console.log("Axios part");
             // console.log(response.data);
             // this.orderentry = response.data;
             this.addCard(response.data);
             loadingComponent.close();
           })
           .catch((error) => { console.log(error); });
           // .finally();
    },
  }
}
</script>
