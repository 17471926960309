<template>
  <div class="receive-pos">
    <h1>PO Receiving</h1>
    <hr>
    <section>
      <b-field label="PO to Receive">
        <section>
          <b-field>
              <b-select v-model='currentSelection' placeholder="Warehouse..." required>
                <option v-for="warehouse in Warehouses" :key="warehouse.message">
                  {{ warehouse.message }}
                </option>
              </b-select>
          </b-field>
          <b-input
              @keyup.native.enter="onSubmit"
              placeholder="PO..."
              v-model="po_id">
          </b-input>
          <!-- <b-notification
            auto-close type="is-danger"
            v-model="orderNotFound"
            aria-close-label="Close notification">
            Order not found.
          </b-notification> -->
        </section>
        <b-button refs="loadable" @click="onSubmit" type="is-primary" label="Receive" />
      </b-field>
    </section>
    <br>

    <div v-if="pocard.length">
      <POCard v-for="card in pocard"
                 :key="card.id"
                 v-bind="card"
                 @remove='removeCard'/>
    </div>
  </div>
</template>

<script>
import POCard from "@/components/POCard.vue"
import axios from "axios"

import { apiKey } from "@/../api/api.json";

export default {
  components: {
    POCard,
  },
  data() {
    return {
      po_id: '',
      pocard: [],
      // orderNotFound: false,
      currentSelection: null,
      Warehouses: [
        { message: 'HOBI' },
        { message: 'WWT' },
        { message: 'CSC' },
        { message: 'ITANCIA' }
      ],
    }
  },
  methods: {
    addCard: function(entry) {
      // console.log("Card part");
      // console.log(entry)
      if(entry.market_order_number){
        this.pocard.pop();
        this.pocard.push(entry);
      } else {
        this.orderNotFound = true;
      }
    },
    removeCard: function() {
      this.pocard.pop();
      this.ordernum = '';
    },
    onSubmit() {
      this.orderNotFound = false;
      const loadingComponent = this.$buefy.loading.open({
                    container: this.$refs.loadable
                });
      this.pocard.pop();
      // console.log('Order number submitted: ', this.ordernum)
      // const axios = require('axios').default;

      // axios.post('http://localhost:3001/api/3po_in.php',
      axios.post('https://finchwright.com/api/3po_in.php',
                 { sub_type: 'receive-po', po: this.po_id, wh: this.currentSelection, apiKey: apiKey },
                 // { headers: { Authorization: `Bearer ${this.apiKey}` } }
                )
           .then((response) => {
             // console.log("Axios part");
             // console.log(response.data);
             // this.orderentry = response.data;
             this.addCard(response.data);
             loadingComponent.close();
           })
           .catch((error) => { console.log(error); });
           // .finally();
    },
  }
}
</script>
