<template>
  <div class="lpn-check">
    <h1>LPN Check</h1>
    <hr>
    <section>
      <b-field label="LPN">
        <section>
          <b-input
              @keyup.native.enter="onSubmit"
              placeholder="Search..."
              type="search"
              icon="magnify"
              v-model="lpn">
          </b-input>
          <b-notification
            auto-close type="is-danger"
            v-model="lpnNotFound"
            aria-close-label="Close notification">
            Lpn not found.
          </b-notification>
        </section>
        <b-button refs="loadable" @click="onSubmit" type="is-primary" label="Search" />
      </b-field>
    </section>
    <br>

    <div v-if="checkcard.length">
      <LPNCheckCard v-for="card in checkcard"
                    :key="card.id"
                    v-bind="card"
                    @remove='removeCard'/>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
  </div>
</template>

<script>
import LPNCheckCard from "@/components/LPNCheckCard.vue"
import axios from "axios"

import { apiKey } from "@/../api/api.json";

export default {
  components: {
    LPNCheckCard,
  },
  data() {
    return {
      lpn: '',
      checkcard: [],
      lpnNotFound: false,
      isLoading: false,
    }
  },
  methods: {
    addCard: function(entry) {
      console.log("Card part");
      console.log(entry)
      if(entry){
        var info = { id: 1, checkinfo: entry }
        this.checkcard.pop();
        this.checkcard.push(info);
      } else {
        this.lpnNotFound = true;
      }
    },
    removeCard: function() {
      this.checkcard.pop();
      this.checkcard.pop();
      this.lpn = '';
    },
    onSubmit() {
      if(this.lpn != ''){
        // this.isLoading = true;
        this.lpnNotFound = false;
        // const loadingComponent = this.$buefy.loading.open({
        //               container: this.$refs.loadable.$el,
        //           });
        this.checkcard.pop();
        this.checkcard.pop();
        // console.log('Order number submitted: ', this.lpn)
        // const axios = require('axios').default;

        // axios.post('http://localhost:3001/api/3po_in.php',
        axios.post('https://finchwright.com/api/3po_in.php',
                   { sub_type: 'lpn-check', lpn: this.lpn, apiKey: apiKey },
                   // { headers: { Authorization: `Bearer ${this.apiKey}` } }
                  )
             .then((response) => {
               // console.log("Axios part");
               // console.log(response.data);
               // this.orderentry = response.data;
               this.addCard(response.data);
               // loadingComponent.close();
               this.isLoading = false;
             })
             .catch((error) => { console.log(error); });
             // .finally();
      }
    },
  }
}
</script>
