<template>
  <div class="r3po">
    <h1>R3PO</h1>
    <b-field class="file is-primary" :class="{'has-name': !!file}">
      <b-upload v-model="file" class="file-label">
        <span class="file-cta">
          <b-icon class="file-icon" icon="upload"></b-icon>
          <span class="file-label">Choose File</span>
        </span>
        <span class="file-name" v-if="file">
          {{ file.name }}
        </span>
      </b-upload>
      <b-button refs="loadable" @click="onFileUpload" type="is-primary" label="Upload" :disabled="!file"/>
    </b-field>
    <hr>
      <section>
        <b-field label="LPNs">
            <b-input type="textarea" v-model='lpn_list'></b-input>
        </b-field>
        <b-input
            placeholder="PO"
            v-model="po_id">
        </b-input>
        <b-field>
            <b-select v-model='currentSelection' placeholder="Select type" required>
              <option v-for="submission_type in SubmissionTypes" :key="submission_type.message">
                {{ submission_type.message }}
              </option>
            </b-select>
        </b-field>
        <b-button refs="loadable" @click="onSubmit" type="is-primary" label="Search" />
      </section>
    <br>

    <div v-if="r3po_card.length">
      <R3poCard v-for="card in r3po_card"
                 :key="card.id"
                 v-bind="card"
                 @remove='removeCard'/>
    </div>
  </div>
</template>

<script>

import R3poCard from "@/components/R3poCard.vue";
import axios from "axios";

import { apiKey } from "@/../api/api.json";

export default {
  components: {
    R3poCard,
  },
  data() {
    return {
      file: null,
      lpn_list: '',
      po_id: '',
      r3po_card: [],
      currentSelection: null,
      SubmissionTypes: [
        { message: 'Inactives' },
        { message: 'Create Products' },
        { message: 'LPNs file' },
        { message: 'PO file' }
      ],
    }
  },
  methods: {
    addCard: function(entry) {
      // console.log("Card part");
      // console.log(entry)
      this.r3po_card.push(entry);
    },
    removeCard: function() {
      this.r3po_card.pop();
    },
    processUploadResponse(data) {
      data.forEach((entry) => { this.addCard(entry) });
    },
    onSubmit() {
      this.orderNotFound = false;
      const loadingComponent = this.$buefy.loading.open({
                    container: this.$refs.loadable
                });
      this.r3po_card.pop();
      // console.log('Order number submitted: ', this.input)
      // const axios = require('axios').default;

      // axios.post('http://localhost:3001/api/3po_in.php',
      axios.post('https://finchwright.com/api/3po_in.php',
                  { sub_type: 'r3po',
                    lpns: this.lpn_list,
                    po_id: this.po_id,
                    type: this.currentSelection,
                    apiKey: apiKey, },
                 // { headers: { Authorization: `Bearer ${this.apiKey}` } }
                )
           .then((response) => {
             // console.log("Axios part");
             // console.log(response.data);
             // this.orderentry = response.data;
             this.addCard(response.data);
             loadingComponent.close();
           })
           .catch((error) => { console.log(error); });
           // .finally();
    },
    onFileUpload(){
      if(this.file != null){
        const loadingComponent = this.$buefy.loading.open({ container: this.$refs.loadable });

        const formData = new FormData();
        formData.append('file',this.file);
        formData.append('type','r3pofileupload');
        formData.append('apiKey',apiKey);

        // console.log('Order number submitted: ', this.lpn)
        // const axios = require('axios').default;

        // const reader = new FileReader();
        // reader.onload = function (event) {
        //   console.log(event.target.result); // the CSV content as string
        // };
        // reader.readAsArrayBuffer(this.file);

        // axios.post('http://localhost:3001/api/upload_in.php',
        axios.post('https://finchwright.com/api/upload_in.php',
                  formData,
                  { headers: { 'Content-Type': 'multipart/form-data' } }
                  //  { sub_type: 'qc-trans-submit', file: formData, apiKey: apiKey },
                   // { headers: { Authorization: `Bearer ${this.apiKey}` } }
                  )
             .then((response) => {
               console.log("Axios part");
               console.log(response.data);
               // this.orderentry = response.data;
               this.processUploadResponse(response.data);
               loadingComponent.close();
              //  this.isLoading = false;
             })
             .catch((error) => { console.log(error); });
             // .finally();
      } else {
        console.log(this.file);
      }
    },
  },
  mounted() {
  }
}
</script>
