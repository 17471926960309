import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomeView.vue'

import { authGuard } from "../auth/authGuard";

import CustomerService from "../views/CustomerService.vue";
import Reports from "../views/ReportsView.vue";
import Returns from "../views/ReturnsView.vue";
import ReturnReport from "../views/ReturnReport.vue";
import EndOfDay from "../views/EndOfDay.vue";
import ClientPerformance from "../views/ClientPerformance.vue";

import C3po from "../views/3poView.vue";
import Onpl from "../views/OnplView.vue";
import OnplTests from "../views/OnplTests.vue";

import SellerCloud from "../views/SellerCloudView.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/customer-service',
    name: 'customer-service',
    component: CustomerService,
    beforeEnter: authGuard
  },
  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    beforeEnter: authGuard
  },
  {
    path: '/returns',
    name: 'returns',
    component: Returns,
    beforeEnter: authGuard
  },
  {
    path: '/returntable',
    name: 'returntable',
    component: ReturnReport,
    beforeEnter: authGuard
  },
  {
    path: '/end-of-day',
    name: 'end-of-day',
    component: EndOfDay,
    beforeEnter: authGuard
  },
  {
    path: '/client-performance',
    name: 'client-performance',
    component: ClientPerformance,
    beforeEnter: authGuard
  },
  {
    path: '/3po',
    name: '3po',
    component: C3po,
    beforeEnter: authGuard
  },
  {
    path: '/onpl',
    name: 'onpl',
    component: Onpl,
    beforeEnter: authGuard
  },
  {
    path: '/onpl-tests',
    name: 'onpl-tests',
    component: OnplTests,
    beforeEnter: authGuard
  },
  {
    path: '/sellercloud',
    name: 'sellercloud',
    component: SellerCloud,
    beforeEnter: authGuard
  },
]

const router = new VueRouter({
  routes,
});

// https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed/58439497#58439497
const originalPush = router.push
router.push = function push(location, onResolve, onReject)
{
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }

    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            return err
        }

        return Promise.reject(err)
    })
};

export default router
