<template>
  <div class="buttons">
    <LogoutButton v-if="$auth.isAuthenticated" />
    <LoginButton v-else />
  </div>
</template>

<script>
  import LoginButton from '@/components/LoginButton';
  import LogoutButton from '@/components/LogoutButton';

  export default {
    name: 'AuthenticationButton',
    components: { LogoutButton, LoginButton },
    methods: {
      // Log the user in
      login() {
        this.$auth.loginWithRedirect();
      },
      //Log the user out
      logout() {
        this.$auth.logout({
          returnTo: window.location.origin
        });
      }
    }
  };
</script>