<template>
  <section>
    <b-collapse
            class="card" 
            animation="slide" 
            aria-id="contentIdForA11y3">
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button"
                    aria-controls="contentIdForA11y3">
                    <p class="card-header-title">
                        {{ table_title }}
                    </p>
                    <a class="card-header-icon">
                      <b-icon
                          :icon="props.open ? 'menu-down' : 'menu-up'">
                      </b-icon>
                    </a>
                    <b-field>
                      <b-button @click="download" type="is-primary">Download</b-button>
                    </b-field>
                </div>
            </template>
            <div class="card-content">
              <div class="content">
                <b-table v-if="current_data.length"
                        :data="current_data"
                        :columns="columns"
                        scrollable
                        striped
                        hoverable>
                </b-table>
              </div>
            </div>
    </b-collapse>
  </section>
</template>

<script>
import Papa from "papaparse"

export default {
  // components:{
  //   // OrderReturnForm,
  //   // OrderCancelForm,
  //   // OrderNoteForm,
    // OrderInfo,
  //   // OrderSkuList
  // },
  props: {
    // data_array: Array,
    imei_data: Array,
    // data_type: String,
  },
  data() {
    return {
      table_title: null,
      info: [],
      current_data: [],
      columns: [
        { field: 'SiteOrderID',label: 'Market Order Number',sticky: true },
        { field: 'ID',label: 'ChAd Order Number' },
        { field: 'lpn',label: 'LPN' },
        { field: 'imei',label: 'IMEI' },
        { field: 'sku',label: 'SKU' },
        { field: 'SiteName',label: 'Site' },
      ],
    }
  },
  methods: {
    onCardSubmit() {
      //
    },
    download() {
      // Making the filename
      var date = new Date();
      var str = date.getFullYear()
              + ("0" + (date.getMonth() + 1)).slice(-2)
              + ("0" + date.getDate()).slice(-2);
      let filename = "finchreport_"+ str + ".csv";
      // credit: https://www.bitdegree.org/learn/javascript-download
      // via: https://www.raymondcamden.com/2020/12/15/vue-quick-shot-downloading-data-as-a-file
      let text = Papa.unparse(this.current_data);

      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);

    },
  },
  mounted() {
    this.table_title = "IMEI info";
    this.current_data = this.imei_data;
  }
}
</script>
