<template>
  <section>
    <b-collapse
            class="card" 
            animation="slide" 
            aria-id="contentIdForA11y3">
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button"
                    aria-controls="contentIdForA11y3">
                    <p class="card-header-title">
                        {{ table_title }}
                    </p>
                    <a class="card-header-icon">
                      <b-icon
                          :icon="props.open ? 'minus' : 'plus'">
                      </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
              <div class="content">
                <b-table v-if="current_data.length"
                        :data="current_data"
                        :columns="columns"
                        scrollable
                        striped
                        hoverable>
                </b-table>
              </div>
            </div>
    </b-collapse>
  </section>
</template>

<script>

export default {
  // components:{
  //   // OrderReturnForm,
  //   // OrderCancelForm,
  //   // OrderNoteForm,
    // OrderInfo,
  //   // OrderSkuList
  // },
  props: {
    // data_array: Array,
    lpn_data: Array,
    exploded_multigrade: Array,
    order_data: Array,
    lpn_history: Array,
    // data_type: String,
  },
  data() {
    return {
      table_title: null,
      info: [],
      current_data: [],
      columns: [],
      lpn_columns: [
        { field: 'lpn',label: 'LPN',sticky: true },
        { field: 'sku',label: 'SKU' },
        { field: 'new_lpn',label: 'New LPN' },
        { field: 'refund_tag',label: 'Refund Tag' },
        { field: 'process_tag',label: 'Process Tag' },
        { field: 'id_part',label: 'ChAd ID', },
        { field: 'skv_sales_id',label: 'SKV ID' },
        { field: 'ship_date',label: 'Shipped Date' },
        { field: 'marketplace',label: 'Marketplace' },
        { field: 'final_unit_cost',label: 'Final Unit Cost' },
      ],
      order_columns: [
        { field: 'market_order_number',label: 'Market Order ID',sticky: true },
        { field: 'chad_order_id',label: 'ChAd ID', },
        { field: 'second_order_id',label: 'Secondary Market ID' },
        { field: 'marketplace',label: 'Marketplace' },
        { field: 'client',label: 'Client' },
        // { field: 'warehouse',label: 'Warehouse' },
        { field: 'note_type',label: 'Note Type' },
        { field: 'substituted',label: 'Substituted' },
        { field: 'replaced',label: 'Replacement' },
        { field: 'refund',label: 'Partial-Refund' },
        { field: 'note_tag',label: 'Note Tag' },
        { field: 'notes',label: 'Notes' },
        { field: 'skus',label: 'SKUs' },
        { field: 'date',label: 'Date Submitted' },
        { field: 'user',label: 'Submitted By' },
      ],
      exploded_multigrade_columns: [
        { field: 'code', label: 'Code' },
        { field: 'description', label: 'Description' },
      ]
    }
  },
  // computed: {
  //   submissionType: function () {
  //     if(this.is_shipped){
  //       return 'Return';
  //     } else {
  //       return 'Cancellation';
  //     }
  //   }
  // },
  methods: {
    onCardSubmit() {
      // this.isReady = true;
      // if(this.is_shipped == true){
      //   this.readyReturn = true;
      // } else {
      //   this.readyCancel = true;
      // }
      // console.log(JSON.stringify(this.selected_skus))
    }
  },
  mounted() {
    if(this.lpn_data){
      this.table_title = "LPN data";
      this.columns = this.lpn_columns;
      this.current_data = this.lpn_data;
    } else if(this.order_data) {
      this.table_title = "Order data";
      this.columns = this.order_columns;
      this.current_data = this.order_data;
    } else if(this.exploded_multigrade){
      this.table_title = "Multigrade Detail";
      this.columns = this.exploded_multigrade_columns;
      this.current_data = this.exploded_multigrade;
    } else if(this.lpn_history){
      this.table_title = "LPN history";
      this.columns = this.lpn_columns;
      this.current_data = this.lpn_history;
    }
  }
}
</script>
