<template>
  <div class="card">
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p class="title is-4">{{ market_order_number }}</p>
          <p class="subtitle is-6">{{ marketplace }} <br> {{ warehouse }} <br> [{{ api_source }}]</p>
        </div>
      </div>

      <div class="content">
        <div>
          <order-info v-for="item in info"
                      v-bind:key="item.id"
                      v-bind="item"/>
        </div>
        <hr>
          <b-collapse
                  class="card" 
                  animation="slide" 
                  aria-id="contentIdForA11y3"
                  v-if="prev_notes && prev_notes.length">
                  <template #trigger="props">
                      <div
                          class="card-header"
                          role="button"
                          aria-controls="contentIdForA11y3">
                          <p class="card-header-title">
                              Previous Order Notes
                          </p>
                          <a class="card-header-icon">
                            <b-icon
                                :icon="props.open ? 'minus' : 'plus'">
                            </b-icon>
                          </a>
                      </div>
                  </template>
                  <div class="card-content">
                    <div class="content">
                      <b-table v-if="prev_notes && prev_notes.length"
                              :data="prev_notes"
                              :columns="note_columns"
                              scrollable>
                      </b-table>
                    </div>
                  </div>
          </b-collapse>
        <hr>
        <section>
          <b-table v-if="dc_list && dc_list.length > 0"
              :data="dc_list"
              :columns="dc_columns"
              :checked-rows.sync="selected_dcs"
              checkable 
              scrollable>
          </b-table>
          <hr>
          <b-table v-if="lpn_list && lpn_list.length > 0"
              :data="lpn_list"
              :columns="lpn_columns"
              :checked-rows.sync="selected_lpns"
              checkable 
              scrollable>
          </b-table>
          <hr>
          <b-table v-if="sku_list && sku_list.length > 0"
              :data="sku_list"
              :columns="columns"
              :checked-rows.sync="selected_skus"
              :checkable="lpn_list.length == 0"
              scrollable>
          </b-table>
        </section>
      </div>
        <OrderNoteForm :orderNotFound="orderNotFound"
                       :isReady="isReady"
                       :isSubmitting="isSubmitting"
                       :orderNum='market_order_number'
                       :secondaryOrderNumber='secondary_order_number'
                       :chadOrderNumber='chad_order_number'
                       :warehouse='warehouse'
                       :marketplace='marketplace'
                       :shipDate='ship_date'
                       :selectedSkus='selected_skus'
                       :selectedDcs='selected_dcs'
                       :selectedLpns='selected_lpns'
                       :submissionType='submissionType'
                       @remove="$emit('remove', idorders)"
                       @failed="isSubmitting = false"/>
      <br>
      <b-field>
        <b-button @click="onCardSubmit" type="is-primary" :disabled="!isReady">Submit</b-button>
      </b-field>
    </div>
  </div>
</template>

<script>
// import OrderReturnForm from "./OrderReturnForm.vue"
// import OrderCancelForm from "./OrderCancelForm.vue"
import OrderNoteForm from "./OrderNoteForm.vue"
import OrderInfo from "./OrderInfo.vue"
// import OrderSkuList from "./OrderSkuList.vue"

export default {
  components:{
    // OrderReturnForm,
    // OrderCancelForm,
    OrderNoteForm,
    OrderInfo,
    // OrderSkuList
  },
  props: {
    orderNotFound: Boolean,
    idorders: Number,
    market_order_number: String,
    secondary_order_number: String,
    chad_order_number: String,
    sku: String,
    title: String,
    cost: String,
    tax: String,
    order_date: String,
    ship_date: String,
    is_shipped: Boolean,
    expiry_date: String,
    return_expired: Boolean,
    marketplace: String,
    warehouse: Object,
    api_source: String,
    prev_notes: Array,
    sku_list: Array,
    dc_list: Array,
    lpn_list: Array,
    // selected_skus: Array,
  },
  data() {
    return {
      activeTab: 0,
      // isReady: false,
      isSubmitting: false,
      readyReturn: false,
      readyCancel: false,
      entry: null,
      info: [],
      selected_skus: [],
      selected_dcs: [],
      selected_lpns: [],
      columns: [
        { field: 'sku',label: 'SKU', },
        { field: 'title',label: 'Title', },
        { field: 'cost',label: 'Unit Price',centered: true },
        // { field: 'promotions',label: 'Discount',centered: true },
        { field: 'total_discount',label: 'Promotion',centered: true },
        { field: 'unit_tax',label: 'Unit Tax',centered: true },
        { field: 'final_price',label: 'Final Unit Price',centered: true },
        { field: 'quantity',label: 'Quantity',centered: true },
      ],
      dc_columns: [
        { field: 'dc',label: 'Distribution Center', },
        { field: 'carrier',label: 'Carrier', },
      ],
      lpn_columns: [
        { field: 'lpn',label: 'LPN', },
        { field: 'sku',label: 'SKU', },
        { field: 'wh',label: 'Warehouse', },
        { field: 'client',label: 'Client', },
      ],
      note_columns: [
        { field: 'market_order_number',label: 'Market Order ID', },
        { field: 'chad_order_id',label: 'ChAd ID', },
        { field: 'second_order_id',label: 'Secondary Market ID' },
        { field: 'warehouse',label: 'Warehouse' },
        { field: 'marketplace',label: 'Marketplace' },
        { field: 'note_type',label: 'Note Type' },
        { field: 'substituted',label: 'Substituted' },
        { field: 'replaced',label: 'Replacement' },
        { field: 'refund',label: 'Partial-Refund' },
        { field: 'note_tag',label: 'Note Tag' },
        { field: 'notes',label: 'Notes' },
        { field: 'skus',label: 'SKUs' },
        { field: 'unit_price',label: 'Unit Price' },
        { field: 'final_unit_price',label: 'Final Unit Price' },
        { field: 'ship_date',label: 'Date Shipped' },
        { field: 'date',label: 'Date Submitted' },
        { field: 'user',label: 'Submitted By' },
      ]
    }
  },
  computed: {
    submissionType: function () {
      if(this.is_shipped){
        return 'Return';
      } else {
        return 'Cancellation';
      }
    },
    isReady: function () {
      // return (this.selected_dcs.length > 0 && this.selected_skus.length > 0);
      return ((this.selected_dcs.length > 0 && (this.selected_lpns.length > 0 || this.selected_skus.length > 0))
          || (this.orderNotFound));
    }
  },
  methods: {
    onCardSubmit() {
      if(this.isReady){
        this.isSubmitting = true;
      }
      // if(this.is_shipped == true){
      //   this.readyReturn = true;
      // } else {
      //   this.readyCancel = true;
      // }
      // console.log(JSON.stringify(this.selected_skus))
    }
  },
  mounted() {
    // console.log('OrderCard says');
    // console.log(this.market_order_number);
    // this.info.push(this.entry);
    var id = 1;
    if(this.market_order_number){ this.info.push({ id: id, label: 'Site Order Number', value: this.market_order_number }); }
    if(this.secondary_order_number){ this.info.push({ id: ++id, label: 'Secondary Order Number', value: this.secondary_order_number }); }
    if(this.chad_order_number){ this.info.push({ id: ++id, label: 'ChAd Order ID', value: this.chad_order_number }); }
    if(this.order_date){ this.info.push({ id: ++id, label: 'Ordered', value: this.order_date }); }
    if(this.ship_date){ this.info.push({ id: ++id, label: 'Shipped', value: this.ship_date }); }
    if(this.expiry_date){ this.info.push({ id: ++id, label: 'Return Expiration', value: this.expiry_date, isExpired: this.return_expired }); }
    // this.info.push({ id: ++id, label: 'Return Expired', value: this.return_expired });
    // this.info.push({ id: ++id, label: 'Title', value: this.title });
    // this.info.push({ id: ++id, label: 'Sku', value: this.sku });
    if(this.cost){ this.info.push({ id: ++id, label: 'Total Sale Price', value: this.cost }); }
    // this.info.push({ id: ++id, label: 'Total Tax', value: this.tax });
    // if(this.prev_notes && this.prev_notes != ""){
    //   var base_id = id;
    //   this.prev_notes.forEach(row => {
    //     this.info.push({ id: ++id, label: 'Note '+(id - base_id), value: JSON.stringify(row) });
    //   });
    // }
    
    if(this.dc_list && this.dc_list.length == 1){
      this.selected_dcs = this.dc_list;
    }
    if(this.sku_list && this.sku_list.length == 1){
      this.selected_skus = this.sku_list;
    }
    if(this.lpn_list && this.lpn_list.length == 1){
      this.selected_lpns = this.lpn_list;
    }
  },
}
</script>
