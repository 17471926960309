<template>
  <div class="returntable">
    <h1>ONPL Report</h1>
    <hr>
    <section>
      <b-field>
        <b-button @click="GetReturnEntries" type="is-primary">Get report</b-button>
      </b-field>
      <b-collapse
              class="card" 
              animation="slide" 
              aria-id="contentIdForA11y3">
              <template #trigger="props">
                  <div
                      class="card-header"
                      role="button"
                      aria-controls="contentIdForA11y3">
                      <p class="card-header-title">
                          {{ table_title }}
                      </p>
                      <b-field>
                        <b-button @click="download" type="is-primary">Download</b-button>
                      </b-field>
                      <a class="card-header-icon">
                        <b-icon
                            :icon="props.open ? 'minus' : 'plus'">
                        </b-icon>
                      </a>
                  </div>
              </template>
              <div class="card">
                <div class="card-content">
                  <section>
                    <b-table 
                            :data="current_data"
                            :selected.sync="selected"
                            bordered
                            narrowed
                            scrollable
                            striped
                            hoverable
                            paginated >

                      <b-table-column field="lpn" label="lpn" sortable v-slot="props" > 
                        {{ props.row.lpn }}
                      </b-table-column>
                      <b-table-column field="order_hnid" label="order_hnid" sortable v-slot="props" > 
                        {{ props.row.order_hnid }}
                      </b-table-column>
                      <b-table-column field="hive_status" label="hive_status" sortable v-slot="props" > 
                        {{ props.row.hive_status }}
                      </b-table-column>
                      <b-table-column field="onpl_status" label="onpl_status" sortable v-slot="props" > 
                        {{ props.row.onpl_status }}
                      </b-table-column>
                      <b-table-column field="c3po_status" label="c3po_status" sortable v-slot="props" > 
                        {{ props.row.c3po_status }}
                      </b-table-column>
                      <b-table-column field="inbound_tracking_number" label="inbound_tracking_number" sortable v-slot="props" > 
                        {{ props.row.inbound_tracking_number }}
                      </b-table-column>
                      <b-table-column field="outbound_tracking_number" label="outbound_tracking_number" sortable v-slot="props" > 
                        {{ props.row.outbound_tracking_number }}
                      </b-table-column>
                      <b-table-column field="date_added" label="date_added" sortable v-slot="props" > 
                        {{ props.row.date_added }}
                      </b-table-column>
                      <b-table-column field="date_updated" label="date_updated" sortable v-slot="props" > 
                        {{ props.row.date_updated }}
                      </b-table-column>
                      <b-table-column field="entered_confirmed" label="entered_confirmed" sortable v-slot="props" > 
                        {{ props.row.entered_confirmed }}
                      </b-table-column>
                      <b-table-column field="entered_canceled" label="entered_canceled" sortable v-slot="props" > 
                        {{ props.row.entered_canceled }}
                      </b-table-column>
                      <b-table-column field="entered_received" label="entered_received" sortable v-slot="props" > 
                        {{ props.row.entered_received }}
                      </b-table-column>
                      <b-table-column field="entered_adjusted" label="entered_adjusted" sortable v-slot="props" > 
                        {{ props.row.entered_adjusted }}
                      </b-table-column>
                      <b-table-column field="entered_declined" label="entered_declined" sortable v-slot="props" > 
                        {{ props.row.entered_declined }}
                      </b-table-column>
                      <b-table-column field="entered_returned" label="entered_returned" sortable v-slot="props" > 
                        {{ props.row.entered_returned }}
                      </b-table-column>
                      <b-table-column field="entered_accepted" label="entered_accepted" sortable v-slot="props" > 
                        {{ props.row.entered_accepted }}
                      </b-table-column>
                      <b-table-column field="entered_completed" label="entered_completed" sortable v-slot="props" > 
                        {{ props.row.entered_completed }}
                      </b-table-column>
                    </b-table>
                  </section>
                  <br>
                </div>
              </div>
      </b-collapse>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import Papa from "papaparse";

import { apiKey } from "@/../api/api.json";

export default {
  components: {

  },
  data(){
    return {
      table_title: '',
      current_data: [],
      selected: null,
      currentTag: 'Ready for refund',
      RefundTags: [
        { message: 'Early refund' },
        { message: 'Ready for refund' },
        { message: 'Pin-locked' },
        { message: 'iCloud-locked' },
        { message: 'Do not refund' },
        { message: 'Fraud' },
      ],
      // columns: [
      //   { field: 'status',label: 'Status', sortable: true },
      //   { field: 'refund_tag',label: 'Type', sortable: true },
      //   { field: 'sku',label: 'SKU', sortable: true },
      //   { field: 'lpn',label: 'Original LPN', sortable: true },
      //   { field: 'market_order_number',label: 'Original Order Number', sortable: true },
      //   { field: 'id_part',label: 'ChAd Order Number', sortable: true },
      //   { field: 'new_lpn',label: 'New LPN', sortable: true },
      //   { field: 'note_tag',label: 'Return/Refund Reason', sortable: true },
      //   { field: 'date',label: 'Return/Refund Entered Date', sortable: true },
      //   { field: 'refund',label: 'Refund Amount' },
      //   { field: 'user',label: 'Order Note Entered By', sortable: true },
      //   { field: 'notes',label: 'Return Notes' },
      //   { field: 'refund_notes',label: 'Refund Notes' },
      // ]
    }
  },
  methods: {
    MakeNewEntry(){
      //
    },
    UpdateTableData(data){
      //
      this.table_title = this.currentTag;
      this.current_data = data;
    },
    GetReturnEntries(){
      const loadingComponent = this.$buefy.loading.open({
                    container: this.$refs.loadable
                });
      // axios.post('http://localhost:3001/api/3po_in.php',
      axios.post('https://finchwright.com/api/3po_in.php',
                 { sub_type: 'onpl-populate-report', apiKey: apiKey },
                 // { headers: { Authorization: `Bearer ${this.apiKey}` } }
                )
           .then((response) => {
             // console.log("Axios part");
             // console.log(response.data);
             this.UpdateTableData(response.data);
             loadingComponent.close();
           })
           .catch((error) => { console.log(error); });
           // .finally();
    },
    download() {
      // Making the filename
      var date = new Date();
      var str = date.getFullYear()
              + ("0" + (date.getMonth() + 1)).slice(-2)
              + ("0" + date.getDate()).slice(-2);
      let filename = "finchreport_"+ str + ".csv";
      // credit: https://www.bitdegree.org/learn/javascript-download
      // via: https://www.raymondcamden.com/2020/12/15/vue-quick-shot-downloading-data-as-a-file
      let text = Papa.unparse(this.current_data);

      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    },
    edit_entry(row){
      switch (this.currentTag) {
        case "Early refund":
        case "Ready for refund":
          this.$buefy.dialog.prompt({
              message: "Refund <b>" + row.id_part + '</b><BR>Refund amount suggested: <b>'+row.refund_suggested +"</b><BR>",
              inputAttrs: {
                  type: 'numeric',
                  placeholder: '0.00',
              },
              confirmText: 'Commit',
              trapFocus: true,
              closeOnConfirm: false,
              onConfirm: (value, {close}) => {
                var val = Number(value);
                if(val){
                  this.UpdateSelectedEntry(row.id_part,row.lpn,val)
                    // this.$buefy.toast.open(`Your message is sending...`)
                    setTimeout(() => {
                        // this.$buefy.toast.open(`Success message send!`)
                        close()
                    }, 500)
                } else {
                  this.$buefy.toast.open(`Value must be a number`);
                }
              }
          });
          break;
        case "Pin-locked":
        case "iCloud-locked":
        case "Do not refund":
        case "Fraud":
          this.$buefy.dialog.confirm({
              message: "Change status for <b>" + row.id_part + "</b><BR>Change to <b>Ready for Refund</b><BR>",
              confirmText: 'Commit',
              trapFocus: true,
              closeOnConfirm: false,
              onConfirm: (value,{close}) => {
                this.UpdateSelectedEntry(row.id_part,row.lpn,"changeToReady")
                  // this.$buefy.toast.open(`Your message is sending...`)
                  setTimeout(() => {
                      // this.$buefy.toast.open(`Success message send!`)
                      close()
                  }, 500)
              }
          });
          break;
        default: break;
      }
    },
  },
  mounted(){
    //
    // this.GetReturnEntries();
  }
}
</script>
