<template>
  <section>
    <b-collapse
            class="card" 
            animation="slide" 
            aria-id="contentIdForA11y3">
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button"
                    aria-controls="contentIdForA11y3">
                    <p class="card-header-title">
                        {{ table_title }}
                    </p>
                    <b-field>
                      <b-button @click="download" type="is-primary">Download</b-button>
                    </b-field>
                    <a class="card-header-icon">
                      <b-icon
                          :icon="props.open ? 'minus' : 'plus'">
                      </b-icon>
                    </a>
                </div>
            </template>
            <div class="card">
              <div class="card-content">
                <section>
                  <b-table v-if="current_data.length"
                          :data="current_data"
                          :columns="columns"
                          scrollable
                          striped
                          hoverable
                          paginated>
                  </b-table>
                </section>
                <br>
              </div>
            </div>
    </b-collapse>
  </section>
</template>

<script>
import Papa from "papaparse"
// import OrderReturnForm from "./OrderReturnForm.vue"
// import OrderCancelForm from "./OrderCancelForm.vue"
// import OrderNoteForm from "./OrderNoteForm.vue"
// import OrderInfo from "./OrderInfo.vue"
// import OrderSkuList from "./OrderSkuList.vue"

export default {
  // components:{
  //   // OrderReturnForm,
  //   // OrderCancelForm,
  //   // OrderNoteForm,
  //   // OrderInfo,
  //   // OrderSkuList
  // },
  props: {
    // data_array: Array,
    lpn_data: Array,
    order_data: Array,
    date_data: Array,
    // data_type: String,
  },
  data() {
    return {
      table_title: null,
      current_type: '',
      current_data: [],
      columns: [],
      lpn_columns: [
        { field: 'id', label: 'id' },
        { field: 'lpn', label: 'LPN' },
        { field: 'new_lpn',label: 'New LPN' },
        { field: 'refund_tag',label: 'Refund Tag' },
        { field: 'process_tag',label: 'Process Tag' },
        { field: 'id_part', label: 'Marketplace id_part' },
        { field: 'skv_sales_id', label: 'skv_sales_id' },
        { field: 'ship_date', label: 'Shipped Date' },
        { field: 'sku', label: 'SKU' },
        { field: 'refunded_amount', label: 'Refunded Amount' },
        { field: 'date', label: 'Date' },
        { field: 'update_date', label: 'Update Date' },
      ],
      order_columns: [
        { field: 'market_order_number',label: 'Market Order ID', },
        { field: 'chad_order_id',label: 'ChAd ID', },
        { field: 'second_order_id',label: 'Secondary Market ID' },
        { field: 'warehouse',label: 'Warehouse' },
        { field: 'marketplace',label: 'Marketplace' },
        { field: 'ship_date',label: 'Shipped Date' },
        { field: 'note_type',label: 'Note Type' },
        { field: 'substituted',label: 'Substituted' },
        { field: 'replaced',label: 'Replacement' },
        { field: 'refund',label: 'Partial-Refund' },
        { field: 'note_tag',label: 'Note Tag' },
        { field: 'notes',label: 'Notes' },
        { field: 'skus',label: 'SKUs' },
        { field: 'date',label: 'Date Submitted' },
        { field: 'user',label: 'Submitted By' },
      ],
    }
  },
  // computed: {
  //   submissionType: function () {
  //     if(this.is_shipped){
  //       return 'Return';
  //     } else {
  //       return 'Cancellation';
  //     }
  //   }
  // },
  methods: {
    // onCardSubmit() {
    //   // this.isReady = true;
    //   // if(this.is_shipped == true){
    //   //   this.readyReturn = true;
    //   // } else {
    //   //   this.readyCancel = true;
    //   // }
    //   // console.log(JSON.stringify(this.selected_skus))
    // }
    download() {
      // Making the filename
      var date = new Date();
      var str = date.getFullYear()
              + ("0" + (date.getMonth() + 1)).slice(-2)
              + ("0" + date.getDate()).slice(-2);
      let filename = "finchreport_"+ str + ".csv";
      // credit: https://www.bitdegree.org/learn/javascript-download
      // via: https://www.raymondcamden.com/2020/12/15/vue-quick-shot-downloading-data-as-a-file
      let text = Papa.unparse(this.current_data);

      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);

    },
  },
  mounted() {
    if(this.lpn_data){
      this.table_title = "LPN data";
      this.columns = this.lpn_columns;
      this.current_data = this.lpn_data;
      this.current_type = 'LPN';
    } else if(this.order_data) {
      this.table_title = "Order data";
      this.columns = this.order_columns;
      this.current_data = this.order_data;
      this.current_type = 'ORDER';
    } else if(this.date_data) {
      this.table_title = "Order range";
      // this.columns = this.date_columns;
      this.columns = this.order_columns;
      this.current_data = this.date_data;
      this.current_type = 'ORDER_RANGE';
    }
  }
}
</script>
