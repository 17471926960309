<template>
  <div>{{ label }} : {{ value }}</div>
</template>

<script>
export default {
  props: {
    label: String,
    value: String,
  },
}
</script>
