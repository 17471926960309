<template>
  <div class="r3po">
    <h1>ONPL Post-Inspection</h1>
    <hr>
      <section>
        <b-field label="LPNs" label-for="lpn-list">
            <b-input id="lpn-list" type="textarea" v-model='lpn_list'></b-input>
        </b-field>
        <b-button refs="loadable" @click="onSubmit" type="is-primary" label="Submit" />
      </section>
    <br>

    <div v-if="onpl_card.length">
      <OnplCard v-for="card in onpl_card"
                 :key="card.id"
                 v-bind="card"
                 @remove='removeCard'/>
    </div>
  </div>
</template>

<script>

import OnplCard from "@/components/OnplCard.vue";
import axios from "axios";

import { apiKey } from "@/../api/api.json";

export default {
  components: {
    OnplCard,
  },
  data() {
    return {
      lpn_list: '',
      onpl_card: [],
    }
  },
  methods: {
    addCard: function(entry) {
      console.log("Card part");
      console.log(entry);
      this.onpl_card.push(entry);
      // entry.forEach((temp) => this.onpl_card.push(temp));
    },
    removeCard: function() {
      this.onpl_card.pop();
    },
    processUploadResponse(data) {
      data.forEach((entry) => { this.addCard(entry) });
    },
    onSubmit() {
      this.orderNotFound = false;
      const loadingComponent = this.$buefy.loading.open({
                    container: this.$refs.loadable
                });
      this.onpl_card.pop();
      // console.log('Order number submitted: ', this.input)
      // const axios = require('axios').default;

      // axios.post('http://localhost:3001/api/3po_in.php',
      axios.post('https://finchwright.com/api/3po_in.php',
                  { sub_type: 'onpl-post-inspection-test',
                    lpns: this.lpn_list,
                    apiKey: apiKey, },
                 // { headers: { Authorization: `Bearer ${this.apiKey}` } }
                )
           .then((response) => {
             console.log("Axios part");
             console.log(response.data);
             // this.orderentry = response.data;
            //  response.data.forEach(this.addCard);
            //  this.addCard(response.data);
             loadingComponent.close();
           })
           .catch((error) => { console.log(error); });
           // .finally();
    },
  },
  mounted() {
  }
}
</script>
