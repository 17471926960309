<template>
  <div class="returns">
    <h1>Order Return Processing</h1>
    <hr>
    <section>
      <b-field label="LPN">
        <section>
          <b-input
              @keyup.native.enter="onSearch"
              placeholder="Search..."
              type="search"
              icon="search"
              v-model="lpn">
          </b-input>
          <b-notification
            auto-close type="is-danger"
            v-model="lpnNotFound"
            aria-close-label="Close notification"
            required>
            Lpn not found.
          </b-notification>
          <b-field label='New LPN'>
            <b-input
                placeholder="New LPN"
                type="input"
                v-model="newlpn">
            </b-input>
          </b-field>
          <b-field label='Tracking number'>
            <b-input
                placeholder="Tracking number"
                type="input"
                v-model="trackingNumber">
            </b-input>
          </b-field>
        </section>
        <b-button refs="loadable" @click="onSearch" type="is-primary" label="Search" />
      </b-field>
      <b-field label='Refund Tag'>
        <b-select v-model='currentRefundTag' placeholder="Refund Tag?" required >
          <option v-for="refundtag in RefundTags" :key="refundtag.message">{{ refundtag.message }}</option>
        </b-select>
      </b-field>
      <b-field v-if="currentRefundTag == 'Early refund' || currentRefundTag == 'Ready for refund'" label='Suggested Refund Amount'>
        <b-input placeholder="0"
                v-model="refundSuggested">
        </b-input>
      </b-field>
      <!-- <b-field v-if="currentRefundTag == 'Pin-locked' || currentRefundTag == 'iCloud-locked'" class="file is-primary" :class="{'has-name': !!imageFile}">
        <b-upload v-model="imageFile" class="file-label">
          <span class="file-cta">
            <b-icon class="file-icon" icon="upload"></b-icon>
            <span class="file-label">Choose File</span>
          </span>
          <span class="file-name" v-if="imageFile">
            {{ imageFile.name }}
          </span>
        </b-upload>
      </b-field> -->
      <b-field label='Next Process'>
        <b-select v-model='currentProcessTag' placeholder="Next Process?" required >
          <option v-for="processtag in NextProcessTags" :key="processtag.message">{{ processtag.message }}</option>
        </b-select>
      </b-field>
      <b-button @click="onSubmit" type="is-primary" label="Submit" />
      <b-notification
        type="is-danger"
        v-model="submitError"
        aria-close-label="Close notification">
        {{ submitErrorMsg }}
      </b-notification>
    </section>
    <br>

    <div v-if="returncard.length">
      <TableCard v-for="card in returncard"
                  :key="card.id"
                  v-bind="card"
                  @remove='removeCard'/>
    </div>

    <br>
    <br>
  </div>
</template>

<script>
// import ReturnCard from "@/components/ReturnCard.vue"
import TableCard from "@/components/TableCard.vue"
import axios from "axios"

import { apiKey } from "@/../api/api.json";

export default {
  components: {
    // ReturnCard,
    TableCard,
  },
  data() {
    return {
      lpn: '',
      newlpn: '',
      trackingNumber: '',
      returncard: [],
      lpnNotFound: false,
      submitError: false,
      submitErrorMsg: "",
      currentProcessTag: null,
      currentRefundTag: null,
      imageFile: null,
      refundSuggested: "0",
      orderData: [],
      NextProcessTags: [
        { message: 'Reprocessing' },
        { message: 'Disposal' },
        { message: 'Hold for Customer' },
      ],
      RefundTags: [
        { message: 'Ready for refund' },
        { message: 'Early refund' },
        { message: 'Hold: Locked' },
        { message: 'Hold: Cracked / Damaged' },
        { message: 'Hold: Wrong Item' },
        { message: 'Do not refund' },
        // Changed per request 2023-10-03
        // { message: 'Early refund' },
        // { message: 'Ready for refund' },
        // { message: 'Pin-locked' },
        // { message: 'iCloud-locked' },
        // { message: 'Do not refund' },
        // { message: 'Fraud' },
      ],
    }
  },
  methods: {
    addCard: function(entry) {
      // console.log("Card part");
      // console.log(entry)
      if(entry){
        if(entry.exploded_multigrade && entry.exploded_multigrade.length > 0){
          // this.returncard.pop();
          var test_c = { exploded_multigrade: entry.exploded_multigrade };
          this.returncard.push(test_c);
        }
        if(entry.lpn_history && entry.lpn_history.length > 0){
          // this.returncard.pop();
          var test_d = { lpn_history: entry.lpn_history };
          this.returncard.push(test_d);
        }
        if(entry.lpn_data && entry.lpn_data.length > 0){
          // this.returncard.pop();
          this.orderData = entry.lpn_data;
          var test_a = { lpn_data: entry.lpn_data };
          this.returncard.push(test_a);
        }
        if(entry.order_data && entry.order_data.length > 0){
          // this.returncard.pop();
          var test_b = { order_data: entry.order_data };
          this.returncard.push(test_b);
        }
      } else {
        this.orderData = [];
        this.lpnNotFound = true;
      }
    },
    checkSuccess: function(result){
      return result[0].update_attempted > 0 || result[1].insert_attempted > 0;
    },
    emptyCards: function() {
      while(this.returncard.length > 0){
        this.returncard.pop();
        console.log(this.returncard.length)
      }
    },
    removeCard: function() {
      // this.returncard.pop();
      this.emptyCards();
      this.lpn = '';
      this.newlpn = '';
      this.trackingNumber = '';
      this.currentRefundTag = null;
      this.currentProcessTag = null;
      this.refundSuggested = '0';
      this.orderData = [];
    },
    onSearch() {
      this.lpnNotFound = false;
      const loadingComponent = this.$buefy.loading.open({
                    container: this.$refs.loadable
                });
      // this.returncard.pop();
      // this.returncard.pop();
      this.emptyCards();
      // console.log('Order number submitted: ', this.lpn)
      // const axios = require('axios').default;

      // axios.post('http://localhost:3001/api/api_in.php',
      axios.post('https://finchwright.com/api/api_in.php',
                 { type: 'returnsearch', lpn: this.lpn, apiKey: apiKey },
                 // { headers: { Authorization: `Bearer ${this.apiKey}` } }
                )
           .then((response) => {
             // console.log("Axios part");
             // console.log(response.data);
            //  this.orderData = response.data;
             this.addCard(response.data);
             loadingComponent.close();
           })
           .catch((error) => { console.log(error); });
           // .finally();
    },
    attachImage() {
      //
    },
    onSubmit() {
      // console.log('Order number submitted: ', this.lpn)
      // const axios = require('axios').default;
      if(this.imageFile == null) {
        // axios.post('http://localhost:3001/api/api_in.php',
        axios.post('https://finchwright.com/api/api_in.php',
                  { type: 'returnsubmit',
                    lpn: this.lpn,
                    newLpn: this.newlpn,
                    trackingNumber: this.trackingNumber,
                    refundTag: this.currentRefundTag,
                    processTag: this.currentProcessTag,
                    refundSuggested: this.refundSuggested,
                    orderData: this.orderData,
                    apiKey: apiKey },
                  // { headers: { Authorization: `Bearer ${this.apiKey}` } }
                  )
            .then((response) => {
              console.log("Axios part");
              console.log(response.data);
              // this.orderentry = response.data;
              if(this.checkSuccess(response.data)){
                this.submitError = false;
                this.submitErrorMsg = "";
                this.removeCard();
              } else {
                this.submitError = true;
                this.submitErrorMsg = response.data[0].update_attempted +"<BR>"+ response.data[0].insert_attempted;
              }
            })
            .catch((error) => { console.log(error); });
            // .finally();
      } else {
        const loadingComponent = this.$buefy.loading.open({ container: this.$refs.loadable });

        const formData = new FormData();
        formData.append('file',this.imageFile);
        formData.append('type','returnsubmit-withimage');
        formData.append('lpn',this.lpn);
        formData.append('newLpn',this.newlpn);
        formData.append('trackingNumber',this.newlpn);
        formData.append('refundTag',this.currentRefundTag);
        formData.append('processTag',this.currentProcessTag);
        formData.append('refundSuggested',this.refundSuggested);
        formData.append('orderData',this.orderData);
        formData.append('apiKey',apiKey);

        // axios.post('http://localhost:3001/api/upload_in.php',
        axios.post('https://finchwright.com/api/upload_in.php',
                  formData,
                  { headers: { 'Content-Type': 'multipart/form-data' } }
                  //  { sub_type: 'qc-trans-submit', file: formData, apiKey: apiKey },
                   // { headers: { Authorization: `Bearer ${this.apiKey}` } }
                  )
             .then((response) => {
                console.log("Axios part");
                console.log(response.data);
                // this.orderentry = response.data;
                if(this.checkSuccess(response.data)){
                  this.submitError = false;
                  this.submitErrorMsg = "";
                  this.removeCard();
                } else {
                  this.submitError = true;
                  this.submitErrorMsg = response.data[0].update_attempted +"<BR>"+ response.data[0].insert_attempted;
                }
               loadingComponent.close();
             })
             .catch((error) => { console.log(error); });
             // .finally();
      }
    },
  }
}
</script>
